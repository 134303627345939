import styled from "@emotion/styled";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  LanguagePolisku,
  aesDecrypt,
  aesEncrypt,
  useAuth,
  useAxios,
} from "@pru-cnp/ui-polisku";
import { WITHDRAWAL_HOST } from "../root.component";

import ModalDataPolis from "../components/Modal";
import BentukPembayaran from "./Form/BentukPembayaran";
import PenarikanDana from "./Form/PenarikanDana";
import Review from "./Form/Review";

import CircleRight from "../assets/circle-right.svg";
import StepRight from "../assets/step-right.svg";

function Form() {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const [language] = React.useState(
    window.localStorage.getItem("language") || "id"
  );
  const { echo } = LanguagePolisku();
  const { state } = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const stepProgress = {
    1: echo("withdrawal"),
    2: echo("payment-method"),
    3: echo("review"),
  };
  const steps = [1, 2, 3];
  const [withdrawalForm, setWithdrawalForm] = useState(
    JSON.parse(aesDecrypt(window.localStorage.getItem("withdrawalForm"))) || {}
  );
  const [step, setStep] = useState(withdrawalForm?.step || 1);

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.localStorage.setItem(
      "withdrawalForm",
      aesEncrypt(JSON.stringify(withdrawalForm))
    );
  }, [withdrawalForm]);

  const renderStep = () => {
    if (step === 1)
      return (
        <PenarikanDana
          withdrawalForm={withdrawalForm}
          setWithdrawalForm={setWithdrawalForm}
        />
      );
    if (step === 2)
      return (
        <BentukPembayaran
          withdrawalForm={withdrawalForm}
          setWithdrawalForm={setWithdrawalForm}
        />
      );
    if (step === 3)
      return (
        <Review
          withdrawalForm={withdrawalForm}
          setWithdrawalForm={setWithdrawalForm}
        />
      );
  };

  const handleNext = () => {
    if (step === 1) {
      if (!withdrawalForm?.alasanPenarikanDana) {
        alert(echo("reason-for-withdrawal-required"));
        return;
      }
      if (withdrawalForm?.alasanPenarikanDana?.label === "") {
        alert(echo("reason-for-withdrawal-required"));
        return;
      }
      if (!withdrawalForm?.tipePenarikanDana) {
        alert(echo("withdrawal-type-required"));
        return;
      }
      if (withdrawalForm?.danaInvestasi?.length === 0) {
        alert(echo("fund-investment-required"));
        return;
      }
      if (!withdrawalForm?.nominalPenarikan) {
        alert(echo("withdrawal-amount-required"));
        return;
      }
      if (
        Number(withdrawalForm?.nominalPenarikan) <
        Number(withdrawalForm?.chosenPolicy?.data?.min_withdraw)
      ) {
        alert(echo("minimum-withdrawal-amount"));
        return;
      }
      if (
        Number(withdrawalForm?.nominalSisa) <
        Number(withdrawalForm?.chosenPolicy?.data?.min_remain)
      ) {
        alert(echo("minimum-remaining-amount"));
        return;
      }
    }

    if (step === 2) {
      if (!withdrawalForm?.bentukPembayaran) {
        alert(echo("payment-method-required"));
        return;
      }
      if (withdrawalForm?.bentukPembayaran?.value === 1) {
        if (
          !withdrawalForm?.premi ||
          withdrawalForm?.premi[0]?.noPolis.length === 0 ||
          withdrawalForm?.premi[0]?.amount.length === 0
        ) {
          alert(echo("premi-required"));
          return;
        }
        const totalPremi = withdrawalForm?.premi?.reduce(
          (acc, curr) => acc + Number(curr.amount),
          0
        );
        if (Number(totalPremi) !== Number(withdrawalForm?.nominalPenarikan)) {
          alert(echo("total-premi-not-match"));
          return;
        }
      }
      if (withdrawalForm?.bentukPembayaran?.value === 2) {
        if (withdrawalForm?.pakaiRekeningLain && withdrawalForm?.rekeningLain) {
          const {
            noRekening,
            namaPemilik,
            namaBank,
            cabangUnit,
            kota,
            jenisMataUang,
          } = withdrawalForm.rekeningLain;
          if (
            !noRekening ||
            !namaPemilik ||
            !namaBank ||
            !cabangUnit ||
            // !kota ||
            !jenisMataUang
          ) {
            alert(echo("bank-invalid"));
            return;
          }
          if (withdrawalForm?.chosenPolicy?.currency === "IDR") {
            if (jenisMataUang?.label === "USD") {
              alert("Dana tidak dapat di transfer ke rekening USD/dollar");
              return;
            }
          }
          if (withdrawalForm?.chosenPolicy?.currency === "VALAS") {
            if (jenisMataUang?.label !== "VALAS") {
              alert(echo("currency-not-match"));
              return;
            }
          }
        } else if (withdrawalForm?.rekeningUtama) {
          const { noRekening, namaPemilik, bankCode } =
            withdrawalForm.rekeningUtama;
          if (
            !noRekening ||
            noRekening === "" ||
            !namaPemilik ||
            namaPemilik === "" ||
            !bankCode ||
            bankCode === ""
          ) {
            alert(echo("bank-invalid"));
            return;
          }
        } else {
          alert(echo("bank-invalid"));
          return;
        }
      }
      if (withdrawalForm?.bentukPembayaran?.value === 3) {
        if (
          !withdrawalForm?.premi ||
          withdrawalForm?.premi[0]?.noPolis.length === 0 ||
          withdrawalForm?.premi[0]?.amount.length === 0
        ) {
          alert(echo("premi-required"));
          return;
        }
        const totalPremi = withdrawalForm?.premi?.reduce(
          (acc, curr) => acc + Number(curr.amount),
          0
        );
        if (withdrawalForm?.pakaiRekeningLain && withdrawalForm?.rekeningLain) {
          const {
            noRekening,
            namaPemilik,
            namaBank,
            cabangUnit,
            kota,
            jenisMataUang,
          } = withdrawalForm.rekeningLain;
          if (
            !noRekening ||
            !namaPemilik ||
            !namaBank ||
            !cabangUnit ||
            // !kota ||
            !jenisMataUang
          ) {
            alert(echo("bank-invalid"));
            return;
          }
          if (withdrawalForm?.chosenPolicy?.currency === "IDR") {
            if (jenisMataUang?.label === "USD") {
              alert("Dana tidak dapat di transfer ke rekening USD/dollar");
              return;
            }
          }
          if (withdrawalForm?.chosenPolicy?.currency === "VALAS") {
            if (jenisMataUang?.label !== "VALAS") {
              alert(echo("currency-not-match"));
              return;
            }
          }
        } else if (withdrawalForm?.rekeningUtama) {
          const { noRekening, namaPemilik, bankCode } =
            withdrawalForm.rekeningUtama;
          if (
            !noRekening ||
            noRekening === "" ||
            !namaPemilik ||
            namaPemilik === "" ||
            !bankCode ||
            bankCode === ""
          ) {
            alert(echo("bank-invalid"));
            return;
          }
        } else {
          alert(echo("bank-invalid"));
          return;
        }
      }
      const bodyFee = {
        pol_no: withdrawalForm?.chosenPolicy?.policy_no,
        is_sharia: withdrawalForm?.chosenPolicy?.is_sharia=="No"?true:false,
        withdraw_type:
          withdrawalForm?.tipePenarikanDana?.value === 0 ? "amount" : "unit",
        composition: [
          ...withdrawalForm?.danaInvestasi.map((fund) => ({
            fund_code: fund.fund_code,
            currency: fund.currency,
            value: Number(fund.withdrawAmount),
          })),
        ],
      };
      useAxios({
        url: `${WITHDRAWAL_HOST}/admin-fee`,
        method: "post",
        body: bodyFee,
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              setWithdrawalForm((prev) => ({
                ...prev,
                adminFee: data?.composition,
              }));
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.id
                  : data.resp_desc?.en;
              alert(responseError || echo("something-went-wrong"));
            }
          } else {
            if (status === 401) {
              alert(echo("session-expired"));
              logout();
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.id
                  : data.resp_desc?.en;
              alert(responseError || echo("something-went-wrong"));
            }
          }
        }
      });
    }
    if (step === 3) {
      setLoading(true);
      let bankAccount = {};
      let billingInvoices = [];
      if (withdrawalForm?.bentukPembayaran?.value === 1) {
        billingInvoices = withdrawalForm?.premi.map((premi) => ({
          pol_no: premi?.noPolis,
          amount: Number(premi.amount)
        }));
      } else if (withdrawalForm?.bentukPembayaran?.value === 2) {
        bankAccount = withdrawalForm?.pakaiRekeningLain
          ? {
            bank_code: withdrawalForm?.rekeningLain?.namaBank?.value,
            bank_name: withdrawalForm?.rekeningLain?.namaBank?.label,
            bank_branch: withdrawalForm?.rekeningLain?.cabangUnit,
            acc_no: withdrawalForm?.rekeningLain?.noRekening,
            acc_name: withdrawalForm?.rekeningLain?.namaPemilik,
            acc_cur: withdrawalForm?.rekeningLain?.jenisMataUang?.label
          }
        : {
            bank_code: withdrawalForm?.rekeningUtama?.bankCode?.value,
            bank_name: withdrawalForm?.rekeningUtama?.bankCode?.label,
            acc_name: withdrawalForm?.rekeningUtama?.namaPemilik,
            bank_branch: "",
            acc_no: withdrawalForm?.rekeningUtama?.noRekening,
          };
      } else {
        billingInvoices = withdrawalForm?.premi.map((premi) => ({
          pol_no: premi?.noPolis,
          amount: Number(premi.amount)
        }));
        bankAccount = withdrawalForm?.pakaiRekeningLain
          ? {
              bank_code: withdrawalForm?.rekeningLain?.namaBank?.value,
              bank_name: withdrawalForm?.rekeningLain?.namaBank?.label,
              bank_branch: withdrawalForm?.rekeningLain?.cabangUnit,
              acc_no: withdrawalForm?.rekeningLain?.noRekening,
              acc_name: withdrawalForm?.rekeningLain?.namaPemilik,
              acc_cur: withdrawalForm?.rekeningLain?.jenisMataUang?.label
            }
          : {
              bank_code: withdrawalForm?.rekeningUtama?.bankCode?.value,
              bank_name: withdrawalForm?.rekeningUtama?.bankCode?.label,
              acc_name: withdrawalForm?.rekeningUtama?.namaPemilik,
              bank_branch: "",
              acc_no: withdrawalForm?.rekeningUtama?.noRekening,
            };
      }

      const documents = withdrawalForm?.dokumen?.map((doc) => ({
        category: doc.jenisDokumen?.value,
        file_name: doc?.file?.name,
        content: doc?.file?.base64,
      }));
      
      const body = {
        pol_no: withdrawalForm?.chosenPolicy?.policy_no,
        purpose: withdrawalForm?.alasanPenarikanDana?.value,
        withdraw_type: withdrawalForm?.tipePenarikanDana?.value === 0 ? "amount" : "unit",
        composition: [
          ...withdrawalForm?.danaInvestasi.map((fund) => ({
            fund_code: fund.fund_code,
            value: Number(fund.withdrawAmount),
            currency: fund.currency
          })),
        ],
        documents
      };

      if (Object.keys(bankAccount).length !== 0)
        body.bank_transfer = bankAccount;
      if (billingInvoices.length>0)
        body.billing = billingInvoices;

      useAxios({
        method: "post",
        url: `${WITHDRAWAL_HOST}/valid`,
        body
      }).then((response) => {
        if (response) {
          const { status, data: dt } = response;
          let cvp = dt?.has_cvp;
          if (status == 200) {
            if (dt.resp_code == 0) {
              if (dt.is_valid) {
                if (dt?.remark) {
                  const remarkAlert =
                  language === "id"
                    ? dt?.remark?.id
                    : dt?.remark?.en;
                  alert(remarkAlert || echo("something-went-wrong"));
                }
                setWithdrawalForm((prev) => ({
                  ...prev,
                  wd_id: dt?.wd_id,
                  ref_no: dt?.ref_no
                }));
                if (!dt?.has_cvp) {
                  navigate("/withdrawal/approval", {
                    state: {
                      cvp,
                    },
                  });
                  setLoading(false);
                  return;
                } else {
                  const body = {
                    pol_no: withdrawalForm?.chosenPolicy?.policy_no,
                    is_sharia:
                      withdrawalForm?.chosenPolicy?.is_sharia === "No"
                        ? true
                        : false,
                    composition: [
                      ...withdrawalForm?.danaInvestasi.map((fund) => ({
                        fund_code: fund.fund_code,
                        value: Number(fund?.withdrawAmount),
                      })),
                    ],
                    withdraw_type:
                      withdrawalForm?.tipePenarikanDana?.value === 0 ? "amount" : "unit",
                  };
                  useAxios({
                    method: "post",
                    url: `${WITHDRAWAL_HOST}/cvp-request`,
                    body,
                  }).then((response) => {
                    if (response) {
                      const { status, data } = response;
                      if (status == 200) {
                        if (data.resp_code == 0) {
                          navigate("/withdrawal/approval", {
                            state: {
                              cvp,
                              transactionId: data.trigger_id,
                            },
                          });
                          setLoading(false);
                        } else {
                          const responseError =
                            language === "id"
                              ? data?.resp_desc?.id
                              : data?.resp_desc?.en;
                          alert(responseError || echo("something-went-wrong"));
                          setLoading(false);
                        }
                      } else {
                        if (status === 401) {
                          alert(echo("session-expired"));
                          logout();
                        } else {
                          const responseError =
                            language === "id"
                              ? data?.resp_desc?.id
                              : data?.resp_desc?.en;
                          alert(responseError || echo("something-went-wrong"));
                          setLoading(false);
                        }
                      }
                    }
                  });
                }
              } else {
                const responseError =
                  language === "id"
                    ? dt?.remark?.id
                    : dt?.remark?.en;
                alert(responseError || echo("something-went-wrong"));
                setLoading(false);
              }
            } else {
              const responseError =
                language === "id"
                  ? dt?.resp_desc?.id
                  : dt?.resp_desc?.en;
              alert(responseError || echo("something-went-wrong"));
              setLoading(false);
            }
          } else {
            if (status === 401) {
              alert(echo("session-expired"));
              logout();
            } else {
              const responseError =
                language === "id"
                  ? dt?.resp_desc?.id
                  : dt?.resp_desc?.en;
              alert(responseError || echo("something-went-wrong"));
              setLoading(false);
            }
          }
        }
      });
    }
    withdrawalForm.step = step + 1 > 3 ? 3 : step + 1;
    window.localStorage.setItem(
      "withdrawalForm",
      aesEncrypt(JSON.stringify(withdrawalForm))
    );
    setStep(step + 1 > 3 ? 3 : step + 1);
  };
  const handleBack = () => {
    if (step === 1) navigate("/withdrawal");
    withdrawalForm.step = step - 1;
    window.localStorage.setItem(
      "withdrawalForm",
      aesEncrypt(JSON.stringify(withdrawalForm))
    );
    setStep(step - 1);
  };
  const renderStepProgress = () => {
    return (
      <>
        {steps.map((currentStep) => (
          <React.Fragment key={currentStep}>
            {step === currentStep ? (
              <StepTextProgress>{stepProgress[currentStep]}</StepTextProgress>
            ) : (
              <StepText>{stepProgress[currentStep]}</StepText>
            )}
            {currentStep < 3 && <StepIcon loading="lazy" src={StepRight} />}
          </React.Fragment>
        ))}
      </>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px",
        backgroundColor: "#f7f7f7",
        paddingBottom: "40px",
      }}
    >
      <DataPolis onClick={() => setOpenModal(true)}>
        <TextDataPolis>{echo("policy-holder-data")}</TextDataPolis>
        <IconDataPolis loading="lazy" src={CircleRight} />
      </DataPolis>
      <FormTitle>
        <FormTitleText>
          {language === "id" ? (
            <>
              {echo("form")} <span>{echo("withdrawal")}</span>
            </>
          ) : (
            <>
              {" "}
              {echo("withdrawal")} <span>{echo("form")}</span>
            </>
          )}
        </FormTitleText>
      </FormTitle>
      <Step>{renderStepProgress()}</Step>
      {renderStep()}
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: "80vw",
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: 10,
          },
        })}
      >
        {loading ? (
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("lg")]: {
                alignSelf: "center",
              },
            })}
          >
            <CircularProgress
              style={{
                padding: "12px 60px",
                marginTop: "40px",
              }}
            />
          </Box>
        ) : (
          <BtnLanjut onClick={handleNext}>{echo("continue")}</BtnLanjut>
        )}
        <Kembali onClick={handleBack}>
          <KembaliDiv>
            <KembaliText>{echo("back")}</KembaliText>
          </KembaliDiv>
        </Kembali>
      </Box>
      <ModalDataPolis
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={state?.chosenPolicy}
        policyHolder={user?.session?.client_data?.policy_holder_name}
      />
    </div>
  );
}

const DataPolis = styled.div`
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  display: flex;
  gap: 0px;
  font-size: 18px;
  color: var(--Primary-Color-Black, #000);
  font-weight: 700;
  padding: 10px 24px;
  width: 75vw;
  cursor: pointer;
`;

const TextDataPolis = styled.p`
  font-family: Open Sans, sans-serif;
`;

const IconDataPolis = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 28px;
`;

const FormTitle = styled.div`
  display: flex;
  gap: 10px;
  font-size: 36px;
  color: #000;
  white-space: wrap;
  width: 80vw;
  margin-top: 40px;
  align-self: center;
`;

const FormTitleText = styled.div`
  font-family: Open Sans, sans-serif;
  font-weight: 700;

  span {
    font-family: Open Sans, sans-serif;
    font-weight: 300;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--Primary-Black-primary, #000);
  font-weight: 600;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 80vw;
`;

const StepTextProgress = styled.p`
  font-family: Open Sans, sans-serif;
  text-decoration: underline;
  color: var(--Primary-Prudential-red, #ed1b2e);
  padding: 0 5px;
  white-space: nowrap;
`;

const StepIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
`;

const StepText = styled.div`
  font-family: Open Sans, sans-serif;
  justify-content: center;
  white-space: nowrap;
  padding: 0 5px;
`;

const BtnLanjut = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  color: var(--Primary-Color-White, #fff);
  white-space: nowrap;
  text-align: center;
  padding: 12px 60px;
  font: 600 18px/156% Open Sans, sans-serif;
  border: 0;
  margin-top: 40px;
  cursor: pointer;
`;

const Kembali = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 18px;
  color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  line-height: 144%;
  padding: 10px;
  margin-top: 40px;
  cursor: pointer;
`;

const KembaliDiv = styled.div`
  display: flex;
  gap: 7px;
`;

const KembaliText = styled.div`
  font-family: Open Sans, sans-serif;
`;

const KembaliIcon = styled.img`
  aspect-ratio: 1.2;
  object-fit: auto;
  object-position: center;
  width: 18px;
  margin: auto 0;
`;

export default Form;
