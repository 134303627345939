import styled from "@emotion/styled";
import React, { useState } from "react";

const TextInput = React.memo(
  ({
    style,
    placeHolder = "",
    required = true,
    type = "text",
    max = "",
    onChange,
    defaultValue = "",
    maxDecimal = 5,
    isNumber = false
  }) => {
    const [inputValue, setInputValue] = useState(defaultValue || "");
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event) => {
      const value = event.target.value;
      if (inputValue?.length > 1 && value === "") return;
      if (type === "number") {
        if (max && value > max) {
          return;
        }
        const decimalPart = value?.split(".")[1];
        if (decimalPart && decimalPart.length > maxDecimal) {
          return;
        }
      }
      if (isNumber) {
        const numericValue = value.replace(/[^0-9.]/g, "");
        setInputValue(numericValue);
        if (onChange) {
          onChange(numericValue);
        }
      } else {
        setInputValue(value);
        if (onChange) {
          onChange(value);
        }
      }
    };

    return (
      <Div style={style}>
        <Div2>
          <InputWrapper>
            <StyledInput
              type={type}
              keyboardType={type === "number" ? "numeric" : "default"}
              max={max}
              maxLength={max}
              value={inputValue}
              onChange={handleChange}
              onFocus={(e) => {
                setIsFocused(true);
                if (type === "number") {
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  );
                }
              }}
            />
            {inputValue === "" && (
              <Placeholder>
                {placeHolder}
                {required && <RedAsterisk>*</RedAsterisk>}
              </Placeholder>
            )}
          </InputWrapper>
        </Div2>
      </Div>
    );
  }
);

export default TextInput;

const Div = styled.div`
  align-items: start;
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 300;
  line-height: 156%;
  padding: 6px 20px;
`;

const Div2 = styled.div`
  display: flex;
  gap: 0px;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 0;
  outline: none;
  line-height: 156%;
  width: 100%;
  background: transparent;
`;

const Placeholder = styled.span`
  font-family: Open Sans, sans-serif;
  position: absolute;
  top: 12px;
  left: 0;
  font-size: 18px;
  font-weight: 300;
  color: black; /* Default placeholder color */
  pointer-events: none;
`;

const RedAsterisk = styled.span`
  color: red;
`;
