import React, { useState } from "react";
import styled from "@emotion/styled";
import { LanguagePolisku } from "@pru-cnp/ui-polisku";

import { formatPrice, formatUnit } from "../../components/utils";

function Review({ withdrawalForm, setWithdrawalForm }) {
  const { echo } = LanguagePolisku();
  const [totalEstimation] = useState(
    withdrawalForm?.danaInvestasi?.reduce(
      (acc, fund) => acc + Number(fund?.unit) * Number(fund?.withdrawAmount),
      0
    )
  );
  return (
    <>
      <TitleStep>{echo("withdrawal-information")}</TitleStep>
      <PenarikanDana
        style={{
          borderRadius: "8px",
        }}
      >
        <PenarikanDanaDiv
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <PenarikanDanaLabel>
            {echo("reason-for-withdrawal")}
          </PenarikanDanaLabel>
          <PenarikanDanaValue>
            {withdrawalForm?.alasanPenarikanDana?.value === "S10"
              ? withdrawalForm?.alasanPenarikanDana?.label
              : echo(withdrawalForm?.alasanPenarikanDana?.label)}
          </PenarikanDanaValue>
        </PenarikanDanaDiv>
        <Separator />
        <PenarikanDanaDiv>
          <PenarikanDanaLabel>{echo("type-of-withdrawal")}</PenarikanDanaLabel>
          <PenarikanDanaValue>
            {echo(withdrawalForm?.tipePenarikanDana?.label)}
          </PenarikanDanaValue>
        </PenarikanDanaDiv>
        <Separator />
        <PenarikanDanaDiv
          style={{
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <PenarikanDanaLabel>{echo("fund-choosen")}</PenarikanDanaLabel>
          <PernarikanDanaFundValueDiv>
            {withdrawalForm?.danaInvestasi[0]?.fund_code !== "" &&
              withdrawalForm?.danaInvestasi?.map((fund, index) => (
                <div key={index}>
                  <PenarikanDanaFundLabel>{fund?.fund}</PenarikanDanaFundLabel>
                </div>
              ))}
            <PenarikanDanaFundLabel>
              {withdrawalForm?.tipePenarikanDana?.value === 0
                ? echo("nominal-withdrawal")
                : echo("unit-withdrawal")}
            </PenarikanDanaFundLabel>
            <PenarikanDanaFundValue>
              {withdrawalForm.tipePenarikanDana?.value === 1
                ? formatUnit(withdrawalForm?.totalUnitPenarikan)
                : formatPrice(
                    withdrawalForm?.nominalPenarikan,
                    withdrawalForm?.chosenPolicy?.currency === "IDR"
                      ? "Rp"
                      : "",
                    withdrawalForm?.chosenPolicy?.currency === "IDR"
                      ? "IDR"
                      : ""
                  )}
            </PenarikanDanaFundValue>
          </PernarikanDanaFundValueDiv>
        </PenarikanDanaDiv>
      </PenarikanDana>
      <Estimasi
        style={{
          borderRadius: "8px",
        }}
      >
        {withdrawalForm?.tipePenarikanDana?.value === 0 ? (
          <EstimasiDiv
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <EstimasiLabel>{echo("total-withdrawal")}</EstimasiLabel>
            <EstimasiValue>
              {formatPrice(
                withdrawalForm?.nominalPenarikan,
                withdrawalForm?.chosenPolicy?.currency === "IDR" ? "Rp" : "",
                withdrawalForm?.chosenPolicy?.currency === "IDR" ? "IDR" : ""
              )}
            </EstimasiValue>
          </EstimasiDiv>
        ) : (
          <>
            <EstimasiDiv
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <EstimasiLabel>{echo("total-nominal-estimation")}</EstimasiLabel>
              <EstimasiValue>
                {formatPrice(
                  totalEstimation,
                  withdrawalForm?.chosenPolicy?.currency === "IDR" ? "Rp" : "",
                  withdrawalForm?.chosenPolicy?.currency === "IDR" ? "IDR" : ""
                )}
              </EstimasiValue>
            </EstimasiDiv>
            <Separator />
            <EstimasiDiv
              style={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <EstimasiLabel>{echo("total-unit")}</EstimasiLabel>
              <EstimasiValue>
                {formatUnit(withdrawalForm?.totalUnitPenarikan)}
              </EstimasiValue>
            </EstimasiDiv>
          </>
        )}
        {withdrawalForm?.adminFee?.length > 0 && (
          <>
            <Separator />
            <EstimasiDiv
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <EstimasiLabel>Biaya Penarikan Dana</EstimasiLabel>
              <EstimasiValue>
                {formatPrice(
                  withdrawalForm?.adminFee?.reduce(
                    (acc, curr) => acc + Number(curr?.amount),
                    0
                  ),
                  withdrawalForm?.chosenPolicy?.currency === "IDR" ? "Rp" : "",
                  withdrawalForm?.chosenPolicy?.currency === "IDR" ? "IDR" : ""
                )}
              </EstimasiValue>
            </EstimasiDiv>
          </>
        )}
      </Estimasi>
      <Pembayaran style={{ borderRadius: "8px" }}>
        <PembayaranDiv
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <PembayaranLabel>{echo("payment-method")}</PembayaranLabel>
          <PembayaranValue>
            {echo(withdrawalForm?.bentukPembayaran?.label)}
          </PembayaranValue>
        </PembayaranDiv>
        {(withdrawalForm?.bentukPembayaran?.value === 1 ||
          withdrawalForm?.bentukPembayaran?.value === 3) && (
          <>
            <Separator />
            <PembayaranDiv
              style={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <PembayaranLabel>{echo("premi-payment")}</PembayaranLabel>
              <PembayaranValueDiv>
                {withdrawalForm?.premi?.map((premi, index) => (
                  <div key={index}>
                    <PembayaranValuePolis
                      style={{ marginTop: index > 0 ? "20px" : "" }}
                    >
                      {echo("policy-no")} {premi?.noPolis}
                    </PembayaranValuePolis>
                    <PembayaranValueLabel>Nominal</PembayaranValueLabel>
                    <PembayaranValueText>
                      {formatPrice(
                        premi?.amount,
                        withdrawalForm?.chosenPolicy?.currency === "IDR"
                          ? "Rp"
                          : "",
                        withdrawalForm?.chosenPolicy?.currency === "IDR"
                          ? "IDR"
                          : ""
                      )}
                    </PembayaranValueText>
                  </div>
                ))}
              </PembayaranValueDiv>
            </PembayaranDiv>
          </>
        )}
      </Pembayaran>
      {(withdrawalForm?.bentukPembayaran?.value === 2 ||
        withdrawalForm?.bentukPembayaran?.value === 3) && (
        <>
          <TitleStep>{echo("bank-information")}</TitleStep>
          <Rekening
            style={{
              borderRadius: "8px",
            }}
          >
            {withdrawalForm?.pakaiRekeningLain ? (
              <>
                <RekeningDiv
                  style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                >
                  <RekeningLabel>{echo("bank-account")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.noRekening}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>{echo("bank-account-name")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.namaPemilik}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>{echo("bank-name")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.namaBank?.label}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>{echo("bank-branch")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.cabangUnit}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                {/* <RekeningDiv>
                  <RekeningLabel>{echo("bank-city")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.kota}
                  </RekeningValue>
                </RekeningDiv> */}
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>{echo("bank-currency")}</RekeningLabel>
                  <RekeningValue>
                    {echo(withdrawalForm?.rekeningLain?.jenisMataUang?.label)}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                {/* <RekeningDiv>
                  <RekeningLabel>{echo("bank-swift-code")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.swiftCode}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>{echo("country")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.negara?.label}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>NPWP</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningLain?.npwp}
                  </RekeningValue>
                </RekeningDiv>
                <Separator /> */}
                <RekeningDiv
                  style={{
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                >
                  <RekeningLabel>{echo("bank-status")}:</RekeningLabel>
                  <RekeningValue>Aktif</RekeningValue>
                </RekeningDiv>
              </>
            ) : (
              <>
                <RekeningDiv
                  style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                >
                  <RekeningLabel>{echo("bank-account")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningUtama?.noRekening}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>{echo("bank-account-name")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningUtama?.namaPemilik}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv>
                  <RekeningLabel>{echo("bank-name")}</RekeningLabel>
                  <RekeningValue>
                    {withdrawalForm?.rekeningUtama?.bankCode?.label}
                  </RekeningValue>
                </RekeningDiv>
                <Separator />
                <RekeningDiv
                  style={{
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                >
                  <RekeningLabel>{echo("bank-status")}</RekeningLabel>
                  <RekeningValue>Aktif</RekeningValue>
                </RekeningDiv>
              </>
            )}
          </Rekening>
        </>
      )}
      {withdrawalForm?.dokumen?.length > 0 && (
        <>
          <TitleStep>{echo("document-information")}</TitleStep>
          {withdrawalForm?.dokumen?.map((dokumen, index) => (
            <Dokumen
              key={index}
              style={{
                borderRadius: "8px",
              }}
            >
              <DokumenTop
                style={{
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                <DokumenTopLabel>{echo("document-type")}</DokumenTopLabel>
                <DokumenTopValue>
                  {echo(dokumen?.jenisDokumen?.label)}
                </DokumenTopValue>
              </DokumenTop>
              <Separator />
              <DokumenBottom
                style={{
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              >
                <>
                  {dokumen?.file?.type === "application/pdf" ? (
                    <a
                      href={dokumen?.file?.base64}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {dokumen?.file?.name}
                    </a>
                  ) : (
                    <ImgDokumen loading="lazy" srcSet={dokumen?.file?.base64} />
                  )}
                </>
              </DokumenBottom>
            </Dokumen>
          ))}
        </>
      )}
    </>
  );
}

export default Review;

const TitleStep = styled.p`
  color: var(--Primary-Color-Black, #000);
  font: 700 24px/32px Open Sans, sans-serif;
  align-self: center;
  margin-top: 40px;
  width: 80vw;
`;

const PenarikanDana = styled.div`
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  color: var(--Primary-Color-Black, #000);
  width: 80vw;
`;

const PenarikanDanaDiv = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  gap: 20px;
  padding: 17px 24px;
`;

const PenarikanDanaLabel = styled.div`
  flex: 1;
  font: 600 12px/18px Open Sans, sans-serif;
`;

const PenarikanDanaValue = styled.div`
  flex: 1;
  font: 300 16px/24px Open Sans, sans-serif;
`;

const PernarikanDanaFundValueDiv = styled.div`
  display: flex;
  padding-bottom: 28px;
  flex-direction: column;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  flex: 1;
  gap: 10px;
`;

const PenarikanDanaFundLabel = styled.div`
  font-family: Open Sans, sans-serif;
  line-height: 156%;
`;

const PenarikanDanaFundValue = styled.div`
  color: #68737a;
  margin-top: 8px;
  font: 600 12px/150% Open Sans, sans-serif;
`;

const Separator = styled.div`
  border: 1;
  height: 1px;
  background-color: #eee;
`;

const Estimasi = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  color: var(--Primary-Color-Black, #000);
  margin-top: 40px;
  width: 80vw;
`;

const EstimasiDiv = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  gap: 20px;
  padding: 17px 24px;
`;

const EstimasiLabel = styled.div`
  flex: 1;
  font: 600 12px/18px Open Sans, sans-serif;
`;

const EstimasiValue = styled.div`
  flex: 1;
  font: 300 16px/24px Open Sans, sans-serif;
`;

const Pembayaran = styled.div`
  display: flex;
  width: 80vw;
  flex-direction: column;
  margin-top: 40px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
`;

const PembayaranDiv = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  gap: 20px;
  color: var(--Primary-Color-Black, #000);
  padding: 17px 24px;
`;

const PembayaranLabel = styled.div`
  flex: 1;
  font: 600 12px/18px Open Sans, sans-serif;
`;

const PembayaranValue = styled.div`
  flex: 1;
  font: 300 16px/24px Open Sans, sans-serif;
`;

const PembayaranValueDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #68737a;
  line-height: 150%;
`;

const PembayaranValuePolis = styled.div`
  color: var(--Primary-Color-Black, #000);
  font: 300 16px Open Sans, sans-serif;
`;

const PembayaranValueLabel = styled.div`
  font-family: Open Sans, sans-serif;
  margin-top: 10px;
  font-size: 12px;
`;

const PembayaranValueText = styled.div`
  font-family: Open Sans, sans-serif;
  margin-top: 8px;
  font-size: 12px;
`;

const Rekening = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 80vw;
  flex-direction: column;
  color: var(--Primary-Color-Black, #000);
`;

const RekeningDiv = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  gap: 20px;
  padding: 17px 24px;
`;

const RekeningLabel = styled.div`
  flex: 1;
  font: 600 12px/18px Open Sans, sans-serif;
`;

const RekeningValue = styled.div`
  flex: 1;
  font: 300 16px/150% Open Sans, sans-serif;
`;

const Dokumen = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 80vw;
  flex-direction: column;
  margin-top: 40px;
`;

const DokumenTop = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  gap: 20px;
  color: var(--Primary-Color-Black, #000);
  padding: 17px 24px;
`;

const DokumenTopLabel = styled.div`
  flex: 1;
  font: 600 12px/18px Open Sans, sans-serif;
`;

const DokumenTopValue = styled.div`
  flex: 1;
  font: 300 16px/24px Open Sans, sans-serif;
`;

const DokumenBottom = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 17px;
  flex-direction: column;
`;

const ImgDokumen = styled.img`
  aspect-ratio: 1.59;
  object-fit: auto;
  object-position: center;
  width: 100%;
  align-self: start;
  max-width: 260px;
`;
