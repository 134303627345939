import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import {
  useAxios,
  aesDecrypt,
  useAuth,
  LanguagePolisku,
} from "@pru-cnp/ui-polisku";
import { WITHDRAWAL_HOST } from "../root.component";

import { formatPrice, formatDate } from "../components/utils";

function Transaction() {
  const { echo } = LanguagePolisku();
  const { logout } = useAuth();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const [language] = React.useState(
    window.localStorage.getItem("language") || "id"
  );
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate()
    )
      .toISOString()
      .split("T")[0];
    const endDate = currentDate.toISOString().split("T")[0];
    const body = {
      policyNumber: user?.policyNumber,
      startDate: startDate,
      endDate: endDate,
      transactionType: "with", //with = withdrawal, top = topup
      transactionStatus: "ALL", //All, O=Oustanding, X = Closed, F = Finished, S = Suspend
    };
    useAxios({
      method: "post",
      url: `${WITHDRAWAL_HOST}/transaction-history`,
      body,
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          if (data.resp_code == 0) {
            setHistory(data.data);
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.Indonesian
                : data.resp_desc?.English;
            alert(responseError || "Terjadi kesalahan.");
          }
        } else {
          if (status === 401) {
            alert(echo("session-expired"));
            logout();
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.Indonesian
                : data.resp_desc?.English;
            alert(responseError || echo("something-went-wrong"));
          }
        }
        setLoading(false);
      }
    });
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case "O":
        return "Outstanding";
      case "X":
        return "Terminate";
      case "F":
        return "Finish";
      case "S":
        return "Suspend";
      default:
        return status;
    }
  };

  return (
    <div
      style={{
        height: history?.length > 0 ? "100%" : "100vh",
        backgroundColor: "#f7f7f7",
        paddingBottom: "20vh",
      }}
    >
      <TopBlue>
        {language === "id" ? (
          <>
            {echo("history")} <span>{echo("transaction")}</span>
          </>
        ) : (
          <>
            {" "}
            {echo("transaction")} <span>{echo("history")}</span>
          </>
        )}
      </TopBlue>
      <TransactionDiv>
        <TransactionTop>
          {loading ? (
            <CircularProgress
              style={{
                marginBottom: "20vh",
              }}
            />
          ) : (
            <TransactionListTitle>
              {history?.length > 0 ? (
                <>
                  {echo("list")} <span>{echo("transaction")}</span>
                </>
              ) : (
                <>
                  {echo("not-yet")} <span>{echo("transaction")}</span>
                </>
              )}
            </TransactionListTitle>
          )}
          {/* <TransactionFilter>
            <FilterIcon
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c385e7e4d62e770aafe459ff5bf4a82b8318ac35f6a2cf11d2e01b01ac9c139a?apiKey=b2d053928e754d30be4e4f99aa30b407&"
            />
            <TransactionFilterText>Filter</TransactionFilterText>
          </TransactionFilter> */}
        </TransactionTop>
        {/* <TransactionCategory>
          <TransactionCategoryText>Penarikan Dana</TransactionCategoryText>
          <TransactionCategoryText>Semua</TransactionCategoryText>
        </TransactionCategory> */}
        {history?.map((item, index) => (
          <TransactionBox key={index}>
            <TransactionBoxTop>
              <TransactionBoxTopTitle>
                {echo("withdrawal")}
              </TransactionBoxTopTitle>
              {/* <TransactionBoxTopStatus>{item?.status}</TransactionBoxTopStatus> */}
            </TransactionBoxTop>
            <Separator style={{ marginTop: "20px" }} />
            <TransactionContent>
              <TransactionContentLabel>
                {echo("policy-no")}
              </TransactionContentLabel>
              <TransactionContentValue>
                {item?.policyNo}
              </TransactionContentValue>
            </TransactionContent>
            <TransactionContent>
              <TransactionContentLabel>
                {echo("withdrawal-amount")}
              </TransactionContentLabel>
              <TransactionContentValue>
                {formatPrice(
                  item?.amountWithdrawal,
                  item?.policyCurrency === "IDR" ? "Rp" : "",
                  item?.policyCurrency === "IDR" ? "IDR" : ""
                )}
              </TransactionContentValue>
            </TransactionContent>
            <TransactionContent>
              <TransactionContentLabel>
                {echo("date-of-withdrawal")}
              </TransactionContentLabel>
              <TransactionContentValue>
                {formatDate(item?.callback_time)}
              </TransactionContentValue>
            </TransactionContent>
          </TransactionBox>
        ))}

        {/* <TransactionBox>
          <TransactionBoxTop>
            <TransactionBoxTopTitle>
              Penarikan Dana (Withdrawal)
            </TransactionBoxTopTitle>
            <TransactionBoxTopStatus style={{ color: "#000" }}>
              Pending
            </TransactionBoxTopStatus>
          </TransactionBoxTop>
          <TransactionStatusDetail>
            Dokumen yang menunjukkan hubungan sebagai keluarga Inti (Suami/
            Istri/ Anak/ Orang Tua) dari Pemegang Polis ID (Kartu Keluarga/ Akte
            Lahir/ Akte Nikah)
          </TransactionStatusDetail>
          <Separator style={{ marginTop: "20px" }} />
          <TransactionContent>
            <TransactionContentLabel>Jumlah penarikan</TransactionContentLabel>
            <TransactionContentValue>Rp 1.953.437,91</TransactionContentValue>
          </TransactionContent>
          <TransactionContent>
            <TransactionContentLabel>
              Tanggal pending transaksi
            </TransactionContentLabel>
            <TransactionContentValue>14 Februari 2024</TransactionContentValue>
          </TransactionContent>
          <TransactionButton>Lengkapi dokumen</TransactionButton>
        </TransactionBox>
        <TransactionBox>
          <TransactionBoxTop>
            <TransactionBoxTopTitle>
              Penarikan Dana (Withdrawal)
            </TransactionBoxTopTitle>
            <TransactionBoxTopStatus style={{ color: "#ED1B2E" }}>
              Ditolak
            </TransactionBoxTopStatus>
          </TransactionBoxTop>
          <TransactionStatusDetail style={{ backgroundColor: "#ED1B2E" }}>
            Pengajuan belum dapat diproses karena jumlah penarikan atau sisa
            nilai tunai saat penarikan tidak sesuai dengan ketentuan minimum
            penarikan untuk PRULink Non Syariah dan Syariah.
          </TransactionStatusDetail>
          <Separator style={{ marginTop: "20px" }} />
          <TransactionContent>
            <TransactionContentLabel>Jumlah penarikan</TransactionContentLabel>
            <TransactionContentValue>
              <TransactionContentValue>Rp 1.953.437,91</TransactionContentValue>
            </TransactionContentValue>
          </TransactionContent>
          <TransactionContent>
            <TransactionContentLabel>
              Tanggal tolak transaksi
            </TransactionContentLabel>
            <TransactionContentValue>14 Februari 2024</TransactionContentValue>
          </TransactionContent>
        </TransactionBox>*/}
      </TransactionDiv>
    </div>
  );
}

export default Transaction;

const TopBlue = styled.div`
  background-color: var(--Primary-Color-Blue-Grey, #e5eaef);
  color: var(--Primary-Color-Black, #000);
  padding-top: 60px;
  padding-bottom: 60px;
  font: 700 36px/48px Open Sans, sans-serif;
  text-align: center;

  span {
    font-weight: 300;
  }
`;

const TransactionDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin-top: 40px;
  align-items: center;
`;

const TransactionTop = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 20px;
  padding: 0 26px;
`;

const TransactionListTitle = styled.div`
  color: var(--Primary-Color-Black, #000);
  font: 700 20px/28px Open Sans, sans-serif;
  span {
    font-weight: 300;
  }
`;

const TransactionFilter = styled.div`
  display: flex;
  gap: 6px;
  font-size: 14px;
  color: var(--Primary-Black-primary, #000);
  white-space: nowrap;
  line-height: 24px;
  margin: auto 0;
`;

const FilterIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 18px;
  margin: auto 0;
`;

const TransactionFilterText = styled.div`
  font-family: Open Sans, sans-serif;
`;

const TransactionCategory = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 12px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 114%;
  padding: 0 26px;
  margin-bottom: 10px;
`;

const TransactionCategoryText = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: italic;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--Primary-White-primary, #fff);
  padding: 16px;
`;

const Separator = styled.div`
  border: 1;
  height: 1px;
  background-color: #eee;
`;

const TransactionBox = styled.div`
  border-radius: 8px;
  background-color: var(--Primary-White-primary, #fff);
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: 400;
  line-height: 138%;
  padding: 20px 24px;
  margin: 10px 26px;
  width: 80vw;
`;

const TransactionBoxTop = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

const TransactionBoxTopTitle = styled.div`
  color: var(--Primary-Black-primary, #000);
  font-family: Open Sans, sans-serif;
`;

const TransactionBoxTopStatus = styled.div`
  color: var(--Secondary-Dark-blue, #1b365d);
  font-family: Open Sans, sans-serif;
  margin: auto 0;
`;

const TransactionContent = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 19px;
  gap: 20px;
  color: var(--Primary-Black-primary, #000);
`;

const TransactionContentLabel = styled.div`
  font-family: Open Sans, sans-serif;
`;

const TransactionContentValue = styled.div`
  font-family: Open Sans, sans-serif;
`;

const TransactionStatusDetail = styled.div`
  border-radius: 6px;
  background-color: var(--Secondary-Dark-blue, #1b365d);
  align-self: stretch;
  margin-top: 20px;
  color: var(--Primary-White-primary, #fff);
  justify-content: center;
  padding: 12px;
  font: 600 12px/18px Open Sans, sans-serif;
`;

const TransactionButton = styled.div`
  font-family: Open Sans, sans-serif;
  justify-content: center;
  border-radius: 30px;
  background-color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  margin-top: 20px;
  color: var(--Primary-Color-White, #fff);
  text-align: center;
  padding: 6px 10px;
`;
