import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import {
  NavbarPolisku,
  FooterPolisku,
  AuthProvider,
  useAuth,
  aesDecrypt,
} from "@pru-cnp/ui-polisku";
import Home from "./pages/Home";
import Form from "./pages/Form";
import Transaction from "./pages/Transaction";
import Persetujuan from "./pages/Persetujuan";
import Submit from "./pages/Form/Submit";

export const WITHDRAWAL_HOST = "/wd";

function RequireAuth({ children }) {
  const { user } = useAuth();

  const location = useLocation();

  return user?.session ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

function NotFound() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h1>404 Halaman Tidak Ditemukan</h1>
    </div>
  );
}

export default function Root(props) {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));

  return (
    <BrowserRouter>
      <AuthProvider userData={user}>
        <NavbarPolisku />
        <Routes>
          <Route
            path="/withdrawal"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/withdrawal/form"
            element={
              <RequireAuth>
                <Form />
              </RequireAuth>
            }
          />
          <Route
            path="/withdrawal/history"
            element={
              <RequireAuth>
                <Transaction />
              </RequireAuth>
            }
          />
          <Route
            path="/withdrawal/approval"
            element={
              <RequireAuth>
                <Persetujuan />
              </RequireAuth>
            }
          />
          <Route
            path="/withdrawal/submit"
            element={
              <RequireAuth>
                <Submit />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <FooterPolisku />
      </AuthProvider>
    </BrowserRouter>
  );
}
