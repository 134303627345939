import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import {
  aesDecrypt,
  LanguagePolisku,
  useAxios,
  useAuth,
} from "@pru-cnp/ui-polisku";
import { Dropdown, DropdownDanaInvestasi } from "../../components/Dropdown";
import TextInput from "../../components/TextInput";
import { CircularProgress, Box } from "@mui/material";

import { formatPrice, formatUnit } from "../../components/utils";
import { alasanPenarikanDana, tipePenarikanDana, namaBank } from "./options";

import { WITHDRAWAL_HOST } from "../../root.component";

function PenarikanDana({ withdrawalForm, setWithdrawalForm }) {
  const [language] = React.useState(
    window.localStorage.getItem("language") || "id"
  );
  const { logout } = useAuth();
  const { echo } = LanguagePolisku();
  const { state } = useLocation();
  const [totalNominalAvailable, setTotalNominalAvailable] = useState(0);
  const [totalEstimation, setTotalEstimation] = useState(
    withdrawalForm?.danaInvestasi?.reduce(
      (acc, fund) => acc + Number(fund?.tunai),
      0
    )
  );
  const [totalUnitWithdrawal, setTotalUnitWithdrawal] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(
    withdrawalForm?.nominalPenarikan || 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const danaInvestasi = [
    ...(withdrawalForm?.danaInvestasi || []),
    ...(state?.chosenPolicy?.data?.fund
      ? state?.chosenPolicy?.data?.fund.reduce((acc, fund) => {
          const existingFund = withdrawalForm?.danaInvestasi?.find(
            (existing) => existing.fund_code === fund.code
          );
          if (!existingFund) {
            acc.push({
              fund_code: fund.code,
              fund_name: fund.name,
              fund: `${fund.code}(${fund.name})`,
              tunai: fund.estimateValue,
              unit: fund.unitPrice,
              checked: false,
              currency: fund.currency,
            });
          }
          return acc;
        }, [])
      : []),
  ];

  useEffect(() => {
    window.localStorage.removeItem("withdrawalForm");
    setIsLoading(true);
    useAxios({
      url: `${WITHDRAWAL_HOST}/eligibility`,
      method: "post",
      body: { policyNumber: state?.chosenPolicy?.policy_no },
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          if (data.resp_code == 0) {
            state.chosenPolicy.data = data.data;
            const bankName = namaBank.find(
              (bank) => bank.value === data?.data.account[0]?.bankCode
            );
            setWithdrawalForm((prev) => ({
              ...prev,
              chosenPolicy: state?.chosenPolicy,
              rekeningUtama: {
                noRekening: data?.data?.account[0]?.no,
                namaPemilik: data?.data.account[0]?.name,
                bankCode: bankName,
              },
            }));
          } else if (data?.resp_code == 8) {
            const responseError =
              language === "id"
                ? data.resp_desc?.Indonesian
                : data.resp_desc?.English;
            alert(responseError || echo("something-went-wrong"));
            state.chosenPolicy.data = data.data;
            setWithdrawalForm((prev) => ({
              ...prev,
              chosenPolicy: state?.chosenPolicy,
              rekeningUtama: {
                noRekening: data?.data?.account[0]?.no,
                namaPemilik: data?.data.account[0]?.name,
                bankCode: data?.data.account[0]?.bankCode,
              },
            }));
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.Indonesian
                : data.resp_desc?.English;
            alert(responseError || echo("something-went-wrong"));
          }
        } else {
          if (status === 401) {
            alert(echo("session-expired"));
            logout();
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.Indonesian
                : data.resp_desc?.English;
            alert(responseError || echo("something-went-wrong"));
          }
        }
      }
      setIsLoading(false);
    });
  }, []);

  const handleDropdownSelect = useCallback((name, selected) => {
    setWithdrawalForm((prev) => ({
      ...prev,
      [name]: selected,
    }));
  }, []);

  const handleDanaInvestasiSelect = useCallback((selected) => {
    setWithdrawalForm((prev) => ({
      ...prev,
      danaInvestasi: selected,
    }));
  }, []);

  const handleFundWithdrawal = (fund, value) => {
    setWithdrawalForm((prev) => ({
      ...prev,
      danaInvestasi: prev.danaInvestasi.map((item) =>
        item.fund_code === fund.fund_code
          ? {
              ...item,
              withdrawAmount: value,
            }
          : item
      ),
    }));
  };

  useEffect(() => {
    setTotalNominalAvailable(
      withdrawalForm?.tipePenarikanDana?.value === 1
        ? withdrawalForm?.danaInvestasi?.reduce(
            (acc, fund) => acc + Number(fund?.tunai) / Number(fund?.unit),
            0
          )
        : withdrawalForm?.danaInvestasi?.reduce(
            (acc, fund) => acc + Number(fund?.tunai),
            0
          )
    );
    setTotalEstimation(
      withdrawalForm?.tipePenarikanDana?.value === 1
        ? withdrawalForm?.danaInvestasi?.reduce(
            (acc, fund) =>
              acc + Number(fund?.unit) * Number(fund?.withdrawAmount),
            0
          )
        : withdrawalForm?.danaInvestasi?.reduce(
            (acc, fund) => acc + Number(fund?.tunai),
            0
          )
    );
    setTotalWithdrawal(
      withdrawalForm?.tipePenarikanDana?.value === 1
        ? withdrawalForm?.danaInvestasi?.reduce(
            (acc, fund) =>
              acc + Number(fund?.unit) * Number(fund?.withdrawAmount),
            0
          )
        : withdrawalForm?.danaInvestasi?.reduce(
            (acc, fund) => acc + Number(fund?.withdrawAmount),
            0
          )
    );
    setTotalUnitWithdrawal(
      withdrawalForm?.danaInvestasi?.reduce(
        (acc, fund) => acc + Number(fund?.withdrawAmount),
        0
      )
    );
  }, [withdrawalForm]);

  useEffect(() => {
    const nominalAvailable = withdrawalForm?.chosenPolicy?.data?.fund?.reduce(
      (acc, fund) => acc + Number(fund?.estimateValue),
      0
    );
    const nominalSisa = nominalAvailable - totalWithdrawal;
    setWithdrawalForm((prev) => ({
      ...prev,
      nominalPenarikan: totalWithdrawal,
      totalUnitPenarikan: totalUnitWithdrawal,
      nominalSisa,
    }));
  }, [totalWithdrawal]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          flexDirection: "column",
        }}
      >
        <CircularProgress
          style={{
            margin: "0px auto",
            display: "block",
          }}
        />
        <div
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            padding: "20px",
          }}
        >
          {echo("loading-data")}
        </div>
      </Box>
    );
  return (
    <>
      <TitleStep>{echo("withdrawal-information")}</TitleStep>
      <FormDiv>
        <Dropdown
          title={echo("reason-for-withdrawal")}
          placeholder={echo("reason-for-withdrawal")}
          useOtherOption={true}
          options={alasanPenarikanDana}
          onSelect={(selected) =>
            handleDropdownSelect("alasanPenarikanDana", selected)
          }
          defaultValue={withdrawalForm?.alasanPenarikanDana}
        />
        <Dropdown
          title={echo("type-of-withdrawal")}
          placeholder={echo("type-of-withdrawal")}
          options={
            state?.chosenPolicy?.data?.fund[0]?.code !== ""
              ? tipePenarikanDana
              : [tipePenarikanDana[0]]
          }
          onSelect={(selected) =>
            handleDropdownSelect("tipePenarikanDana", selected)
          }
          defaultValue={withdrawalForm?.tipePenarikanDana}
        />
        <DropdownDanaInvestasi
          options={danaInvestasi}
          onSelect={handleDanaInvestasiSelect}
          chosenPolicy={withdrawalForm?.chosenPolicy}
        />
      </FormDiv>
      <BottomSeparator />
      {withdrawalForm?.danaInvestasi?.length > 0 &&
        withdrawalForm?.danaInvestasi?.map((fund, index) => (
          <div key={index}>
            {fund?.fund_code !== "" && (
              <ChosenDanaInvestasi>{fund?.fund}</ChosenDanaInvestasi>
            )}
            <ChosenDanaInvestasiContent
              style={{ paddingTop: fund?.fund_code === "" && 4 }}
            >
              <ChosenAvailableDana>
                {withdrawalForm.tipePenarikanDana?.value === 1
                  ? `${echo("unit-available")}: `
                  : `${echo("nominal-available")}: `}
                {fund?.fund_code !== ""
                  ? withdrawalForm.tipePenarikanDana?.value === 1
                    ? formatUnit(Number(fund?.tunai) / fund?.unit)
                    : formatPrice(
                        fund?.tunai,
                        fund?.currency === "IDR" ? "Rp" : "",
                        fund?.currency === "IDR" ? "IDR" : ""
                      )
                  : formatPrice(
                      fund?.tunai,
                      withdrawalForm?.chosenPolicy?.currency === "IDR"
                        ? "Rp"
                        : "",
                      withdrawalForm?.chosenPolicy?.currency === "IDR"
                        ? "IDR"
                        : ""
                    )}
              </ChosenAvailableDana>
              <TextInput
                placeHolder={
                  withdrawalForm.tipePenarikanDana?.value === 1
                    ? `${echo("unit-withdrawal")} `
                    : `${echo("nominal-withdrawal")} `
                }
                type="number"
                maxDecimal={
                  withdrawalForm.tipePenarikanDana?.value === 1 ? 5 : 2
                }
                max={totalNominalAvailable}
                onChange={(value) => handleFundWithdrawal(fund, value)}
                defaultValue={fund?.withdrawAmount}
              />
              {withdrawalForm?.tipePenarikanDana?.value === 1 && (
                <ChosenEstimatiedNominal>
                  {echo("total-nominal-estimation")} :{" "}
                  {formatPrice(
                    fund?.withdrawAmount * fund?.unit,
                    fund?.currency === "IDR" ? "Rp" : "",
                    fund?.currency === "IDR" ? "IDR" : ""
                  )}
                </ChosenEstimatiedNominal>
              )}
            </ChosenDanaInvestasiContent>
            <BottomSeparator style={{ marginTop: 10 }} />
          </div>
        ))}
      {totalWithdrawal > 0 && (
        <TotalDanaWithdrawal>
          <TotalDanaWithdrawalTitle>
            Jumlah penarikan dana
          </TotalDanaWithdrawalTitle>
          <TotalDanaWithdrawalDiv>
            <TotalDanaWithdrawalDivLabel>
              {withdrawalForm?.tipePenarikanDana?.value === 1
                ? echo("total-unit")
                : echo("total-nominal")}
            </TotalDanaWithdrawalDivLabel>
            <TotalDanaWithdrawalDivValue>
              {withdrawalForm?.tipePenarikanDana?.value === 1
                ? formatUnit(totalUnitWithdrawal)
                : formatPrice(
                    totalWithdrawal,
                    withdrawalForm?.chosenPolicy?.currency === "IDR"
                      ? "Rp"
                      : "",
                    withdrawalForm?.chosenPolicy?.currency === "IDR"
                      ? "IDR"
                      : ""
                  )}
            </TotalDanaWithdrawalDivValue>
          </TotalDanaWithdrawalDiv>
          {withdrawalForm?.tipePenarikanDana?.value === 1 && (
            <TotalDanaWithdrawalDiv>
              <TotalDanaWithdrawalDivLabel>
                {echo("total-nominal-estimation")}
              </TotalDanaWithdrawalDivLabel>
              <TotalDanaWithdrawalDivValue>
                {formatPrice(
                  totalEstimation,
                  withdrawalForm?.chosenPolicy?.currency === "IDR" ? "Rp" : "",
                  withdrawalForm?.chosenPolicy?.currency === "IDR" ? "IDR" : ""
                )}
              </TotalDanaWithdrawalDivValue>
            </TotalDanaWithdrawalDiv>
          )}
        </TotalDanaWithdrawal>
      )}
    </>
  );
}

export default PenarikanDana;

const TitleStep = styled.p`
  color: var(--Primary-Color-Black, #000);
  font: 700 24px/32px Open Sans, sans-serif;
  align-self: start;
  margin-top: 40px;
  align-self: center;
  width: 80vw;
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
`;

const BottomSeparator = styled.div`
  border: 1;
  height: 1px;
  background-color: #d8d8d8;
  width: 80vw;
`;

const ChosenDanaInvestasi = styled.p`
  color: #000;
  align-self: center;
  font: 600 18px/156% Open Sans, sans-serif;
  width: 80vw;
`;

const ChosenAvailableDana = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const ChosenDanaInvestasiContent = styled.div`
  color: #000;
  width: 100%;
  font: 400 11px/138% Open Sans, sans-serif;
  align-self: center;
  width: 80vw;
`;

const ChosenWithdrawalDiv = styled.div`
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  padding: 10px 24px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
`;

const ChosenWithdrawalBox = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ChosenWithdrawalLabel = styled.div`
  display: flex;
  gap: 0px;
  font-size: 14px;
  font-weight: 700;
  line-height: 171%;
`;

const ChosenWithdrawalTypeLabel = styled.div`
  color: #000;
  font-family: Open Sans, sans-serif;

  span {
    color: #ed1b2e;
    font-family: Open Sans, sans-serif;
  }
`;

const ChosenWithdrawalValue = styled.div`
  color: #000;
  font: 300 18px/156% Open Sans, sans-serif;
`;

const ChosenEstimatiedNominal = styled.p`
  color: var(--Primary-Color-Black, #000);
  margin-top: 10px;
  width: 100%;
  font: 600 12px/150% Open Sans, sans-serif;
`;

const TotalDanaWithdrawal = styled.div`
  align-items: start;
  border-radius: 8px;
  background-color: var(--Primary-White-primary, #fff);
  display: flex;
  flex-direction: column;
  color: var(--Primary-Color-Black, #000);
  text-align: start;
  line-height: 150%;
  padding: 32px 30px;
  margin-top: 40px;
  width: 75vw;
`;

const TotalDanaWithdrawalTitle = styled.div`
  font: 700 18px/156% Open Sans, sans-serif;
`;

const TotalDanaWithdrawalDiv = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const TotalDanaWithdrawalDivLabel = styled.div`
  font: 600 12px Open Sans, sans-serif;
`;

const TotalDanaWithdrawalDivValue = styled.div`
  margin-top: 8px;
  font: 300 16px Open Sans, sans-serif;
`;
