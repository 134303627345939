export const alasanPenarikanDana = [
  {
    value: "S01",
    label: "need-cash",
  },
  {
    value: "S02",
    label: "cash-trouble",
  },
  {
    value: "S05",
    label: "buy-new-policy",
  },
  {
    value: "S06",
    label: "investment-performance",
  },
  {
    value: "S07",
    label: "cash-value-for-premium",
  },
  {
    value: "S14",
    label: "unsatisfied-investment",
  },
  {
    value: "S15",
    label: "investment-result",
  },
];
export const tipePenarikanDana = [
  {
    label: "nominal",
    value: 0,
  },
  {
    label: "unit",
    value: 1,
  },
];

export const bentukPembayaran = [
  {
    label: "premi-or-contribution",
    value: 1,
  },
  {
    label: "transfer",
    value: 2,
  },
  {
    label: "transfer-and-premi",
    value: 3,
  },
];
export const jenisDokumen = [
  {
    label: "id-ktp",
    value: "11010201",
  },
  {
    label: "bank-statement",
    value: "13010299",
  },
  {
    label: "other-document",
    value: "14999999",
  },
];

export const namaBank = [
  {
    value: "000",
    label: "FOREX",
  },
  {
    value: "002",
    label: "BANK BRI",
  },
  {
    value: "003",
    label: "BANK EKSPOR INDONESIA",
  },
  {
    value: "008",
    label: "BANK MANDIRI",
  },
  {
    value: "009",
    label: "BANK BNI",
  },
  {
    value: "011",
    label: "BANK DANAMON",
  },
  {
    value: "013",
    label: "BANK PERMATA",
  },
  {
    value: "014",
    label: "BANK BCA",
  },
  {
    value: "016",
    label: "BANK BII MAYBANK",
  },
  {
    value: "019",
    label: "BANK PANIN",
  },
  {
    value: "020",
    label: "BANK ARTA NIAGA KENCANA",
  },
  {
    value: "022",
    label: "BANK CIMB NIAGA",
  },
  {
    value: "023",
    label: "BANK UOB INDONESIA (BANK BUANA INDONESIA)",
  },
  {
    value: "026",
    label: "BANK LIPPO",
  },
  {
    value: "028",
    label: "BANK OCBC NISP",
  },
  {
    value: "030",
    label: "AMERICAN EXPRESS BANK LTD",
  },
  {
    value: "031",
    label: "CITIBANK",
  },
  {
    value: "032",
    label: "JP. MORGAN CHASE BANK, N.A.",
  },
  {
    value: "033",
    label: "BANK OF AMERICA, N.A",
  },
  {
    value: "034",
    label: "ING INDONESIA BANK",
  },
  {
    value: "036",
    label: "BANK MULTICOR",
  },
  {
    value: "037",
    label: "BANK ARTHA GRAHA INTERNASIONAL",
  },
  {
    value: "039",
    label: "BANK CREDIT AGRICOLE INDOSUEZ",
  },
  {
    value: "040",
    label: "THE BANGKOK BANK COMP. LTD",
  },
  {
    value: "041",
    label: "BANK HSBC",
  },
  {
    value: "042",
    label: "THE BANK OF TOKYO MITSUBISHI UFJ LTD",
  },
  {
    value: "045",
    label: "BANK SUMITOMO MITSUI INDONESIA",
  },
  {
    value: "046",
    label: "BANK DBS INDONESIA",
  },
  {
    value: "047",
    label: "BANK RESONA PERDANIA",
  },
  {
    value: "048",
    label: "BANK MIZUHO INDONESIA",
  },
  {
    value: "050",
    label: "STANDARD CHARTERED BANK",
  },
  {
    value: "052",
    label: "BANK ABN AMRO",
  },
  {
    value: "053",
    label: "BANK KEPPEL TATLEE BUANA",
  },
  {
    value: "054",
    label: "BANK CAPITAL INDONESIA",
  },
  {
    value: "057",
    label: "BANK BNP PARIBAS INDONESIA",
  },
  {
    value: "059",
    label: "KOREA EXCHANGE BANK DANAMON",
  },
  {
    value: "060",
    label: "RABOBANK INTERNASIONAL INDONESIA",
  },
  {
    value: "061",
    label: "BANK ANZ INDONESIA",
  },
  {
    value: "067",
    label: "DEUTSCHE AG",
  },
  {
    value: "068",
    label: "BANK WOORI SAUDARA",
  },
  {
    value: "069",
    label: "BANK OF CHINA",
  },
  {
    value: "076",
    label: "BANK BUMI ARTA",
  },
  {
    value: "087",
    label: "BANK EKONOMI",
  },
  {
    value: "088",
    label: "BANK ANTARDAERAH",
  },
  {
    value: "089",
    label: "BANK HAGA",
  },
  {
    value: "093",
    label: "BANK IFI",
  },
  {
    value: "095",
    label: "BANK CENTURY",
  },
  {
    value: "097",
    label: "BANK MAYAPADA",
  },
  {
    value: "110",
    label: "BANK JABAR",
  },
  {
    value: "111",
    label: "BANK DKI JAKARTA",
  },
  {
    value: "112",
    label: "BPD DIY (YOGYAKARTA)",
  },
  {
    value: "113",
    label: "BANK JATENG (JAWA TENGAH)",
  },
  {
    value: "114",
    label: "BANK JATIM (JAWA TIMUR)",
  },
  {
    value: "115",
    label: "BPD JAMBI",
  },
  {
    value: "116",
    label: "BPD ACEH",
  },
  {
    value: "117",
    label: "BANK SUMUT",
  },
  {
    value: "118",
    label: "BANK NAGARI (BANK SUMBAR)",
  },
  {
    value: "119",
    label: "BANK RIAU KEPRI",
  },
  {
    value: "120",
    label: "BANK SUMSEL BABEL",
  },
  {
    value: "121",
    label: "BANK LAMPUNG",
  },
  {
    value: "122",
    label: "BANK KALSEL (BANK KALIMANTAN SELATAN)",
  },
  {
    value: "123",
    label: "BANK KALBAR (BANK KALIMANTAN BARAT)",
  },
  {
    value: "124",
    label: "BANK KALTIMTARA (BANK KALIMANTAN TIMUR DAN UTARA)",
  },
  {
    value: "125",
    label: "BANK KALTENG (BANK KALIMANTAN TENGAH)",
  },
  {
    value: "126",
    label: "BANK SULSELBAR (BANK SULAWESI SELATAN DAN BARAT)",
  },
  {
    value: "127",
    label: "BANK SULUTGO (BANK SULAWESI UTARA DAN GORONTALO)",
  },
  {
    value: "128",
    label: "BANK NTB",
  },
  {
    value: "129",
    label: "BANK BPD BALI",
  },
  {
    value: "130",
    label: "BANK NTT",
  },
  {
    value: "131",
    label: "BANK MALUKU MALUT",
  },
  {
    value: "132",
    label: "BANK PAPUA",
  },
  {
    value: "133",
    label: "BANK BENGKULU",
  },
  {
    value: "134",
    label: "BANK SULTENG (BANK SULAWESI TENGAH)",
  },
  {
    value: "135",
    label: "BANK SULTRA",
  },
  {
    value: "137",
    label: "BANK BPD BANTEN",
  },
  {
    value: "145",
    label: "BANK NUSANTARA PARAHYANGAN",
  },
  {
    value: "146",
    label: "BANK SWADESI (BANK OF INDIA INDONESIA)",
  },
  {
    value: "147",
    label: "BANK MUAMALAT",
  },
  {
    value: "151",
    label: "BANK MESTIKA DHARMA",
  },
  {
    value: "152",
    label: "BANK SHINHAN INDONESIA (BANK METRO EXPRESS)",
  },
  {
    value: "153",
    label: "BANK SINARMAS",
  },
  {
    value: "157",
    label: "BANK MASPION INDONESIA",
  },
  {
    value: "159",
    label: "BANK HAGAKITA",
  },
  {
    value: "161",
    label: "BANK GANESHA",
  },
  {
    value: "162",
    label: "BANK WINDU KENTJANA",
  },
  {
    value: "164",
    label: "BANK ICBC INDONESIA (HALIM INDONESIA BANK)",
  },
  {
    value: "166",
    label: "BANK HARMONI INTERNATIONAL",
  },
  {
    value: "167",
    label: "BANK QNB KESAWAN (BANK QNB INDONESIA)",
  },
  {
    value: "200",
    label: "BANK BTN",
  },
  {
    value: "212",
    label: "BANK HIMPUNAN SAUDARA 1906",
  },
  {
    value: "213",
    label: "BANK BTPN",
  },
  {
    value: "229",
    label: "BANK CIMB NIAGA SYARIAH",
  },
  {
    value: "300",
    label: "BANK BTPN",
  },
  {
    value: "405",
    label: "BANK VICTORIA SYARIAH",
  },
  {
    value: "422",
    label: "BANK SYARIAH INDONESIA",
  },
  {
    value: "425",
    label: "BANK BJB SYARIAH",
  },
  {
    value: "426",
    label: "BANK MEGA",
  },
  {
    value: "427",
    label: "BANK BNI SYARIAH",
  },
  {
    value: "441",
    label: "BANK BUKOPIN",
  },
  {
    value: "451",
    label: "BANK SYARIAH MANDIRI (BSM)",
  },
  {
    value: "459",
    label: "BANK BISNIS INTERNASIONAL",
  },
  {
    value: "466",
    label: "BANK SRI PARTHA",
  },
  {
    value: "472",
    label: "BANK JASA JAKARTA",
  },
  {
    value: "484",
    label: "BANK BINTANG MANUNGGAL",
  },
  {
    value: "485",
    label: "BANK MNC INTERNASIONAL (BANK BUMIPUTERA)",
  },
  {
    value: "490",
    label: "BANK YUDHA BHAKTI",
  },
  {
    value: "491",
    label: "BANK MITRANIAGA",
  },
  {
    value: "494",
    label: "BANK BRI AGRO NIAGA",
  },
  {
    value: "498",
    label: "BANK SBI INDONESIA (BANK INDOMONEX)",
  },
  {
    value: "501",
    label: "BANK ROYAL INDONESIA",
  },
  {
    value: "503",
    label: "BANK NATIONAL NOBU (BANK ALFINDO)",
  },
  {
    value: "506",
    label: "BANK MEGA SYARIAH",
  },
  {
    value: "513",
    label: "BANK INA PERDANA",
  },
  {
    value: "517",
    label: "BANK HARFA",
  },
  {
    value: "520",
    label: "PRIMA MASTER BANK",
  },
  {
    value: "521",
    label: "BANK BUKOPIN SYARIAH",
  },
  {
    value: "523",
    label: "BANK SAHABAT SAMPEORNA (BANK DIPO INTERNATIONAL)",
  },
  {
    value: "525",
    label: "BANK AKITA",
  },
  {
    value: "526",
    label: "LIMAN INTERNATIONAL BANK",
  },
  {
    value: "531",
    label: "BANK AMAR INDONESIA",
  },
  {
    value: "535",
    label: "BANK KESEJAHTERAAN EKONOMI",
  },
  {
    value: "536",
    label: "BANK BCA SYARIAH",
  },
  {
    value: "542",
    label: "BANK ARTOS INDONESIA",
  },
  {
    value: "547",
    label: "BANK BTPN SYARIAH",
  },
  {
    value: "548",
    label: "BANK MULTI ARTA SENTOSA",
  },
  {
    value: "553",
    label: "BANK MAYORA INDONESIA",
  },
  {
    value: "555",
    label: "BANK INDEX SELINDO",
  },
  {
    value: "558",
    label: "BANK EKSEKUTIF",
  },
  {
    value: "559",
    label: "CENTRATAMA NASIONAL BANK",
  },
  {
    value: "562",
    label: "BANK FAMA INTERNASIONAL",
  },
  {
    value: "564",
    label: "BANK MANDIRI TASPEN POS (BANK SINAR HARAPAN BALI)",
  },
  {
    value: "566",
    label: "BANK VICTORIA INTERNATIONAL",
  },
  {
    value: "567",
    label: "BANK HARDA",
  },
  {
    value: "688",
    label: "BPR KS (KARYAJATNIKA SEDAYA)",
  },
  {
    value: "770",
    label: "BPD SULSELBAR SYARIAH",
  },
  {
    value: "771",
    label: "BANK DANAMON SYARIAH",
  },
  {
    value: "777",
    label: "MAYBANK INDONESIA SYARIAH",
  },
  {
    value: "780",
    label: "BANK CIMB NIAGA SYARIAH",
  },
  {
    value: "781",
    label: "BPD KALSEL SYARIAH",
  },
  {
    value: "782",
    label: "BPD SUMUT SYARIAH",
  },
  {
    value: "784",
    label: "BANK PERMATA SYARIAH",
  },
  {
    value: "786",
    label: "BANK JAGO SYARIAH",
  },
  {
    value: "787",
    label: "BPD SUMSEL BABEL SYARIAH",
  },
  {
    value: "789",
    label: "INDOSAT DOMPETKU",
  },
  {
    value: "790",
    label: "BPD KALTIM & KALTARA SYARIAH",
  },
  {
    value: "791",
    label: "BPD YOGYAKARTA SYARIAH",
  },
  {
    value: "792",
    label: "BPD SUMBAR SYARIAH",
  },
  {
    value: "794",
    label: "BPD JATIM SYARIAH",
  },
  {
    value: "796",
    label: "BPD JATENG SYARIAH",
  },
  {
    value: "798",
    label: "BANK SINARMAS SYARIAH",
  },
  {
    value: "799",
    label: "",
  },
  {
    value: "911",
    label: "TELKOMSEL TCASH",
  },
  {
    value: "945",
    label: "BANK AGRIS (BANK FINCONESIA)",
  },
  {
    value: "946",
    label: "BANK MERINCORP",
  },
  {
    value: "947",
    label: "BANK MAYBANK INDOCORP",
  },
  {
    value: "948",
    label: "BANK OCBC – INDONESIA",
  },
  {
    value: "949",
    label: "BANK CTBC (CHINA TRUST) INDONESIA",
  },
  {
    value: "950",
    label: "BANK COMMONWEALTH",
  },
  {
    value: "1000",
    label: "BANK OVERSEAS",
  },
  {
    value: "2001",
    label: "RAKUTEN CARD",
  },
  {
    value: "9496",
    label: "STANDARD CHARTERED BANK (Singapore)",
  },
  {
    value: "KESAWAN2",
    label: "KESAWAN (USD)",
  },
  {
    value: "LAMPUNG1",
    label: "BPD LAMPUNG (IDR)",
  },
  {
    value: "LAMPUNG2",
    label: "BPD LAMPUNG (USD)",
  },
  {
    value: "LIMAN",
    label: "LIMAN",
  },
  {
    value: "LIPPO",
    label: "CIMB NIAGA",
  },
  {
    value: "MANDIRI1",
    label: "BANK MANDIRI (IDR)",
  },
  {
    value: "MANDIRI2",
    label: "BANK MANDIRI (USD)",
  },
  {
    value: "MANDIRS1",
    label: "MANDIRI SYARIAH (IDR)",
  },
  {
    value: "MANDIRS2",
    label: "MANDIRI SYARIAH (USD)",
  },
  {
    value: "MASPION1",
    label: "MASPION (IDR)",
  },
  {
    value: "MASPION2",
    label: "MASPION (USD)",
  },
  {
    value: "MAYAPAD1",
    label: "MAYAPADA (IDR)",
  },
  {
    value: "MAYAPAD2",
    label: "MAYAPADA (USD)",
  },
  {
    value: "MAYBANK1",
    label: "MAYBANK (IDR)",
  },
  {
    value: "MAYBANK2",
    label: "MAYBANK (USD)",
  },
  {
    value: "MAYORA1",
    label: "MAYORA (IDR)",
  },
  {
    value: "MAYORA2",
    label: "MAYORA (USD)",
  },
  {
    value: "MEGA 1",
    label: "MEGA (IDR)",
  },
  {
    value: "MEGA 2",
    label: "MEGA (USD)",
  },
  {
    value: "MEGASYA1",
    label: "MEGA SYARIAH (IDR)",
  },
  {
    value: "MEGASYA2",
    label: "MEGA SYARIAH (USD)",
  },
  {
    value: "MESTIKA1",
    label: "MESTIKA (IDR)",
  },
  {
    value: "MESTIKA2",
    label: "MESTIKA (USD)",
  },
  {
    value: "METRO 1",
    label: "METRO EKSPRES (IDR)",
  },
  {
    value: "METRO 2",
    label: "METRO EKSPRES (USD)",
  },
  {
    value: "MI.NIAGA",
    label: "MITRANIAGA",
  },
  {
    value: "MIZUHO",
    label: "MIZUHO INDONESIA",
  },
  {
    value: "MUAMALA1",
    label: "MUAMALAT (IDR)",
  },
  {
    value: "MUAMALA2",
    label: "MUAMALAT (USD)",
  },
  {
    value: "MULTICOR",
    label: "MULTICOR",
  },
  {
    value: "NIAGA 1",
    label: "CIMB NIAGA (IDR)",
  },
  {
    value: "NIAGA 2",
    label: "CIMB NIAGA (USD)",
  },
  {
    value: "NISP 1",
    label: "NISP (IDR)",
  },
  {
    value: "NISP 2",
    label: "NISP (USD)",
  },
  {
    value: "NSPARAH1",
    label: "BANK NUSANTARA PARAHYANGAN IDR",
  },
  {
    value: "NSPARAH2",
    label: "BANK NUSANTARA PARAHYANGAN USD",
  },
  {
    value: "OCBCSYS1",
    label: "OCBC NISP SYARIAH (IDR)",
  },
  {
    value: "OCBCSYS2",
    label: "OCBC NISP SYARIAH (USD)",
  },
  {
    value: "PANIN 1",
    label: "PANIN (IDR)",
  },
  {
    value: "PANIN 2",
    label: "PANIN (USD)",
  },
  {
    value: "PANINSY1",
    label: "PANIN SYARIAH (IDR)",
  },
  {
    value: "PANINSY2",
    label: "PANIN SYARIAH (USD)",
  },
  {
    value: "PERMATA1",
    label: "PERMATA (IDR)",
  },
  {
    value: "PERMATA2",
    label: "PERMATA",
  },
  {
    value: "PERMTSY1",
    label: "PERMATA SYARIAH (IDR)",
  },
  {
    value: "PERMTSY2",
    label: "PERMATA SYARIAH (USD)",
  },
  {
    value: "PIKKO",
    label: "PIKKO",
  },
  {
    value: "PRIMA MA",
    label: "PRIMA MASTER",
  },
  {
    value: "RABOBAN1",
    label: "RABOBANK (IDR)",
  },
  {
    value: "RABOBAN2",
    label: "RABOBANK (USD)",
  },
  {
    value: "RESONA P",
    label: "RESONA PERDANIA",
  },
  {
    value: "RIAUSYA1",
    label: "BPD RIAU SYARIAH (IDR)",
  },
  {
    value: "RIAUSYA2",
    label: "BPD RIAU SYARIAH (USD)",
  },
  {
    value: "ROYALBAN",
    label: "ROYAL BANK",
  },
  {
    value: "SAUDARA",
    label: "SAUDARA",
  },
  {
    value: "SCB 1",
    label: "STANDARD CHARTERED BANK (IDR)",
  },
  {
    value: "SCB 2",
    label: "STANDARD CHARTERED BANK (USD)",
  },
  {
    value: "SCBKK",
    label: "SCB KARTU KREDIT",
  },
  {
    value: "SHINTA",
    label: "SHINTA",
  },
  {
    value: "SINARMA1",
    label: "SINARMAS (IDR)",
  },
  {
    value: "SINARMA2",
    label: "SINARMAS (USD)",
  },
  {
    value: "SULUT1",
    label: "BPD SULUT (IDR)",
  },
  {
    value: "SULUT2",
    label: "BPD SULUT (USD)",
  },
  {
    value: "SUMITOMO",
    label: "SUMITOMO MITSUI",
  },
  {
    value: "SUMSESY1",
    label: "BPD SUMSEL SYARIAH (IDR)",
  },
  {
    value: "SUMSESY2",
    label: "BPD SUMSEL SYARIAH (USD)",
  },
  {
    value: "SUMUTSY1",
    label: "BPD SUMUT SYARIAH (IDR)",
  },
  {
    value: "SUMUTSY2",
    label: "BPD SUMUT SYARIAH (USD)",
  },
  {
    value: "SWADESI1",
    label: "SWADESI (IDR)",
  },
  {
    value: "SWADESI2",
    label: "SWADESI (USD)",
  },
  {
    value: "SWAGUNA",
    label: "SWAGUNA",
  },
  {
    value: "SYSUBAR1",
    label: "BPD.SUMATRA BARAT SYARIAH IDR",
  },
  {
    value: "SYSUBAR2",
    label: "BPD.SUMATRA BARAT SYARIAH USD",
  },
  {
    value: "SYSUMBAR",
    label: "BPD.SUMATRA BARAT SYARIAH",
  },
  {
    value: "TASPEN 1",
    label: "BANK MANDIRI TASPEN POS (IDR)",
  },
  {
    value: "TASPEN 2",
    label: "BANK MANDIRI TASPEN POS (USD)",
  },
  {
    value: "TOKYO",
    label: "TOKYO MITSUBISHI",
  },
  {
    value: "UOBINDO1",
    label: "UOB INDONESIA (IDR)",
  },
  {
    value: "UOBINDO2",
    label: "UOB INDONESIA (USD)",
  },
  {
    value: "UTAMA",
    label: "UTAMA",
  },
  {
    value: "VICTORI1",
    label: "VICTORIA (IDR)",
  },
  {
    value: "VICTORI2",
    label: "VICTORIA (USD)",
  },
  {
    value: "WINDU KE",
    label: "WINDU KENTJANA",
  },
  {
    value: "WOORI",
    label: "WOORI BANK INDONESIA",
  },
  {
    value: "YUDHABAK",
    label: "YUDHA BAKTI",
  },
];
export const jenisMataUang = [
  {
    label: "IDR",
    value: 1,
  },
  {
    label: "USD",
    value: 2,
  },
  {
    label: "VALAS",
    value: 3,
  },
];
