export function formatPrice(angka, prefix, currency) {
  if (currency === "IDR") {
    let number_string = angka
        .toString()
        .replace(/[^.\d]/g, "")
        .toString(),
      split = number_string.split("."),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      let separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    if (split[1] != undefined) {
      rupiah += "," + split[1].substr(0, 2).padEnd(2, "0");
    } else {
      rupiah += ",00";
    }

    return prefix == undefined ? rupiah : rupiah ? "Rp " + rupiah : "";
  }
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return USDollar.format(angka);
}

export function formatUnit(angka) {
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 5,
    maximumFractionDigits: 5,
  });
  const result = formatter.format(angka);

  return result;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const getBase64 = (file, cb) => {
  const reader = new FileReader();

  reader.onload = function () {
    cb(reader.result);
  };

  reader.onerror = function (error) {
    console.log("File Read Error: ", error);
  };

  if (file.type.startsWith("image/")) {
    // Handle image compression
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the desired width and height for the compressed image
      const maxWidth = 1920;
      const maxHeight = 1080;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the canvas to a base64 string
      const dataUrl = canvas.toDataURL(file.type, 0.7); // 0.7 is the quality parameter (0 to 1)
      cb(dataUrl);
    };
    img.onerror = function (error) {
      console.log("Image Load Error: ", error);
    };
  } else if (file.type === "application/pdf") {
    // Handle PDF file
    reader.readAsDataURL(file);
  } else {
    console.log("Unsupported file type");
  }
};

export default getBase64;
