import React from "react";
import { Modal } from "@mui/material";
import styled from "@emotion/styled";
import { LanguagePolisku } from "@pru-cnp/ui-polisku";

function ModalPolis({ open = false, onClose, data = {}, policyHolder = "" }) {
  const { echo } = LanguagePolisku();
  return (
    <Modal
      sx={{
        padding: 4,
        alignSelf: "center",
      }}
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalDataPolis>
        <ModalDataPolisTop
          style={{
            borderTopRightRadius: "8px",
            borderTopLeftRadius: "8px",
          }}
        >
          {echo("policy-holder-data")}
        </ModalDataPolisTop>
        <ModalDataPolisContent>
          <ModalDataPolisData>
            <ModalDataPolisField
              style={{
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
              }}
            >
              <ModalDataPolisFieldLabel>
                {echo("policy-no")}
              </ModalDataPolisFieldLabel>
              <ModalDataPolisFieldValue>
                {data?.policy_no}
              </ModalDataPolisFieldValue>
            </ModalDataPolisField>
            <Separator />
            <ModalDataPolisField>
              <ModalDataPolisFieldLabel>
                {echo("product-name")}
              </ModalDataPolisFieldLabel>
              <ModalDataPolisFieldValue>
                {data?.product_name}
              </ModalDataPolisFieldValue>
            </ModalDataPolisField>
            <Separator />
            <ModalDataPolisField>
              <ModalDataPolisFieldLabel>
                {echo("policy-holder")}
              </ModalDataPolisFieldLabel>
              <ModalDataPolisFieldValue>
                {policyHolder}
              </ModalDataPolisFieldValue>
            </ModalDataPolisField>
            <Separator />
            <ModalDataPolisField>
              <ModalDataPolisFieldLabel>
                Tertanggung/
                <br />
                peserta
              </ModalDataPolisFieldLabel>
              <ModalDataPolisFieldValue>
                {data?.main_insured_name}
              </ModalDataPolisFieldValue>
            </ModalDataPolisField>
            <Separator />
            <ModalDataPolisField>
              <ModalDataPolisFieldLabel>
                {echo("policy-status")}
              </ModalDataPolisFieldLabel>
              <ModalDataPolisFieldValue>
                {data?.policy_status === "In Force" ? "Aktif" : "Tidak Aktif"}
              </ModalDataPolisFieldValue>
            </ModalDataPolisField>
            <Separator />
            <ModalDataPolisField
              style={{
                borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
            >
              <ModalDataPolisFieldLabel>
                {echo("currency")}
              </ModalDataPolisFieldLabel>
              <ModalDataPolisFieldValue>
                {data?.currency}
              </ModalDataPolisFieldValue>
            </ModalDataPolisField>
            <Separator />
          </ModalDataPolisData>
          <ModalDataPolisBottom>
            <ModalDataPolisCloseBtn onClick={onClose}>
              {echo("close")}
            </ModalDataPolisCloseBtn>
          </ModalDataPolisBottom>
        </ModalDataPolisContent>
      </ModalDataPolis>
    </Modal>
  );
}

export default ModalPolis;

const ModalDataPolis = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  align-items: start;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-Color-White, #fff);
  color: var(--Primary-Color-Black, #000);
  justify-content: center;
  font: 700 18px/156% Open Sans, sans-serif;
  border-radius: 8px;
  height: min-content;
`;

const ModalDataPolisTop = styled.div`
  display: flex;
  text-align: left;
  align-items: flex-start;
  background-color: var(--Primary-Color-White, #fff);
  color: var(--Primary-Color-Black, #000);
  padding: 24px;
  font: 700 18px Open Sans, sans-serif;
  justify-content: flex-start;
`;

const ModalDataPolisContent = styled.div`
  background-color: var(--Secondary-Medium-grey, #eee);
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ModalDataPolisData = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  color: var(--Primary-Color-Black, #000);
`;

const ModalDataPolisField = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  gap: 20px;
  padding: 17px 24px;
  align-items: center;
`;

const ModalDataPolisFieldLabel = styled.div`
  flex: 0.8;
  font: 600 12px/18px Open Sans, sans-serif;
`;

const ModalDataPolisFieldValue = styled.div`
  flex: 1;
  margin: auto 0;
  font: 300 16px/150% Open Sans, sans-serif;
  align-self: start;
`;

const ModalDataPolisBottom = styled.div`
  border-radius: 8px;
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  font-size: 18px;
  color: var(--Primary-Color-White, #fff);
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  line-height: 156%;
  justify-content: center;
  padding: 17px 24px;
`;

const ModalDataPolisCloseBtn = styled.button`
  font-family: Open Sans, sans-serif;
  justify-content: center;
  border-radius: 30px;
  background-color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  padding: 4px 10px;
  padding: 12px;
  color: #fff;
  border: 0;
  font: 300 16px/18px Open Sans, sans-serif;
  cursor: pointer;
`;

const Separator = styled.div`
  border: 1;
  height: 1px;
  background-color: #eee;
`;
