import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { aesDecrypt, LanguagePolisku } from "@pru-cnp/ui-polisku";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/search.svg";

function Home() {
  const [language] = React.useState(
    window.localStorage.getItem("language") || "id"
  );
  const { echo } = LanguagePolisku();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPolicyData, setFilteredPolicyData] = useState([]);
  const [chosenPolicy, setChosenPolicy] = useState(null);

  const handleCardClick = (policy) => {
    setChosenPolicy(policy);
    window.localStorage.removeItem("withdrawalForm");
  };

  useEffect(() => {
    if (chosenPolicy) {
      handleNext();
    }
  }, [chosenPolicy]);

  useEffect(() => {
    const delay = setTimeout(() => {
      const filteredData = user?.session?.client_data?.policies?.filter(
        (item) =>
          item?.policy_no?.toString().includes(searchTerm) &&
          (item?.policy_status === "In Force" ||
            item?.policy_status === "IF" ||
            item?.policy_status === "Lapsed")
      );
      setFilteredPolicyData(filteredData);
      setChosenPolicy(null);
      window.localStorage.removeItem("withdrawalForm");
    }, 300);

    return () => clearTimeout(delay);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/withdrawal/form", {
      state: {
        chosenPolicy,
      },
    });
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f7f7f7",
        paddingBottom: "20vh",
        height: "100%",
      })}
    >
      <Top>
        <Title>
          {language === "id" ? (
            <>
              {echo("list")} <span>{echo("policy")}</span>
            </>
          ) : (
            <>
              {" "}
              {echo("policy")} <span>{echo("list")}</span>
            </>
          )}
        </Title>
        <Search>
          <Img loading="lazy" src={SearchIcon} />
          <input
            type="text"
            style={{
              fontFamily: "Open Sans, sans-serif",
              border: 0,
              fontSize: "18px",
              lineHeight: "28px",
              fontWeight: 400,
              width: "100%",
              outline: "none",
            }}
            value={searchTerm}
            onChange={handleSearch}
            placeholder={echo("find-policy-no")}
          />
        </Search>
      </Top>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "50px",
          marginTop: 4,
          gap: 4,
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            flexWrap: "wrap",
          },
        })}
      >
        {filteredPolicyData.map((item, index) => (
          <Card
            key={index}
            isActive={item === chosenPolicy}
            onClick={() => handleCardClick(item)}
            style={{
              border: item === chosenPolicy ? "1px solid #ed1a2d" : "",
              minWidth: "40%",
            }}
          >
            <CardTop>
              <LabelProduk>{echo("policy-no")}</LabelProduk>
              <NamaProduk>{item?.policy_no}</NamaProduk>
            </CardTop>
            <Separator />
            <CardBottom>
              <LabelProduk>{echo("product-name")}</LabelProduk>
              <NamaProduk>{item?.product_name}</NamaProduk>
            </CardBottom>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <BtnKembali
          style={{ marginRight: chosenPolicy ? "" : "10vw" }}
          onClick={() => navigate("/")}
          disable
        >
          {echo("back")}
        </BtnKembali>
      </Box>
    </Box>
  );
}

const Top = styled.div`
  background-color: var(--Primary-Color-Blue-Grey, #e5eaef);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px 120px 0;
`;

const Title = styled.p`
  color: var(--Primary-Color-Black, #000);
  font: 700 36px/48px Open Sans, sans-serif;

  span {
    font-weight: 300;
  }
`;

const Search = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-White-primary, #fff);
  display: flex;
  gap: 20px;
  font-size: 18px;
  color: #68737a;
  font-weight: 400;
  text-align: center;
  line-height: 156%;
  padding: 16px 19px;
  margin-bottom: -30px;
  width: 80vw;
  align-self: center;
`;

const Img = styled.img`
  height: 30px;
  width: 30px;
  aspect-ratio: 1.89;
  object-fit: auto;
  object-position: center;
  align-self: center;
`;

const Card = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
`;

const CardTop = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  flex-direction: row;
  padding: 17px 24px;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Separator = styled.div`
  border: 1;
  height: 1px;
  background-color: #eee;
`;

const CardBottom = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  flex-direction: row;
  padding: 17px 24px;
  align-items: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const LabelProduk = styled.div`
  flex: 1;
  font: 600 12px/18px Open Sans, sans-serif;
  align-self: start;
`;

const NamaProduk = styled.div`
  flex: 1;
  font: 300 16px/24px Open Sans, sans-serif;
`;

const BtnLanjut = styled.button`
  border-radius: 30px;
  background-color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  color: var(--Primary-Color-White, #fff);
  white-space: nowrap;
  text-align: center;
  padding: 12px 60px;
  font: 600 18px/156% Open Sans, sans-serif;
  border: 0;
  margin-top: 30px;
  cursor: pointer;
  width: max-content;
  margin-right: 10vw;
  margin-bottom: 20px;
  align-self: end;

  @media (max-width: 768px) {
    width: 80vw;
    margin-right: 0;
    align-self: center;
  }
`;

const BtnKembali = styled.button`
  border-radius: 30px;
  background: transparent;
  color: black;
  white-space: nowrap;
  text-align: center;
  padding: 12px 60px;
  font: 600 18px/156% Open Sans, sans-serif;
  border: 0;
  margin-top: 30px;
  cursor: pointer;
  width: max-content;
  margin-right: 20px;
  margin-bottom: 20px;
  align-self: end;

  @media (max-width: 768px) {
    width: 80vw;
    margin-right: 0;
    align-self: center;
  }
`;

export default Home;
