import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { LanguagePolisku } from "@pru-cnp/ui-polisku";

import CloseIcon from "../assets/dropdown-close.svg";
import OpenIcon from "../assets/dropdown-open.svg";
import Checked from "../assets/checked.svg";

import { formatPrice, formatUnit } from "./utils";

export const Dropdown = ({
  title = "",
  placeholder = "",
  options,
  useOtherOption = false,
  onSelect,
  defaultValue = null,
  required = true,
  isNoTranslate = false,
}) => {
  const { echo } = LanguagePolisku();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue || null);
  const [customValue, setCustomValue] = useState(
    defaultValue?.value === "S10" ? defaultValue?.label : ""
  );
  const [searchQuery, setSearchQuery] = useState("");

  const handleClick = () => {
    setIsOpen(!isOpen);
    if (customValue !== "") {
      setSelectedValue({ label: customValue, value: "S10" });
      onSelect({ label: customValue, value: "S10" });
    }
  };

  const handleSelect = (selected) => {
    setSelectedValue(selected);
    onSelect(selected);
    if (useOtherOption) {
      setCustomValue("");
      if (selected?.value?.length <= 3) setIsOpen(false);
      return;
    } else {
      setIsOpen(false);
    }
  };

  const handleCustomChange = (event) => {
    setCustomValue(event.target.value);
    onSelect({ label: event.target.value, value: "S10" });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOptions = isNoTranslate
    ? options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : options;

  return (
    <>
      {title !== "" && <TitleField>{title}</TitleField>}
      <FormBox>
        <FormDropdown
          style={{
            borderBottomLeftRadius: isOpen ? 0 : "8px",
            borderBottomRightRadius: isOpen ? 0 : "8px",
          }}
        >
          <FormDropdownDiv1>
            <DropdownLabel>
              {selectedValue
                ? isNoTranslate
                  ? selectedValue.label
                  : customValue !== ""
                  ? selectedValue.label
                  : echo(selectedValue.label) === undefined
                  ? selectedValue.label
                  : echo(selectedValue.label)
                : placeholder}
              {!selectedValue && required && <span>*</span>}
            </DropdownLabel>
            <DropdownIcon
              onClick={handleClick}
              loading="lazy"
              src={isOpen ? OpenIcon : CloseIcon}
            />
          </FormDropdownDiv1>
        </FormDropdown>
        {isOpen && (
          <FormDropdownExpand>
            {isNoTranslate && (
              <input
                style={{
                  font: "300 18px/156% Open Sans, sans-serif",
                  border: 0,
                  padding: "12px 24px",
                  outline: "none",
                }}
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search..."
              />
            )}
            {filteredOptions.map((option) => (
              <DropdownItem
                style={{
                  backgroundColor:
                    selectedValue?.value === option.value ? "#E5EAEF" : "",
                }}
                key={option.value}
                onClick={() => handleSelect(option)}
                selected={selectedValue?.value === option.value}
              >
                {isNoTranslate ? option.label : echo(option.label)}
              </DropdownItem>
            ))}
            {useOtherOption && (
              <DropdownItemOther>
                <DropdownItemOtherDiv>
                  <DropdownItemOtherBox>
                    <DropdownItemOtherLabel>
                      {echo("other-reason")}
                    </DropdownItemOtherLabel>
                    <input
                      style={{
                        font: "300 18px/156% Open Sans, sans-serif",
                        border: 0,
                        outline: "none",
                      }}
                      type="text"
                      value={customValue}
                      onChange={handleCustomChange}
                      placeholder={echo("kids-education")}
                    />
                  </DropdownItemOtherBox>
                </DropdownItemOtherDiv>
              </DropdownItemOther>
            )}
          </FormDropdownExpand>
        )}
      </FormBox>
    </>
  );
};

export const DropdownDanaInvestasi = React.memo(
  ({ options, onSelect, chosenPolicy }) => {
    const { echo } = LanguagePolisku();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [fundOptions, setFundOptions] = useState(options);

    const handleClick = () => {
      setIsOpen(!isOpen);
      const selectedFunds = fundOptions.filter((item) => item.checked);
      const selectedFundCodes = selectedFunds.map((item) => item.fund_code);
      setSelectedValue(selectedFundCodes.join(", "));
      onSelect(selectedFunds);
    };

    useEffect(() => {
      const selectedFunds = fundOptions.filter((item) => item.checked);
      const selectedFundCodes = selectedFunds.map((item) => item.fund_code);
      setSelectedValue(selectedFundCodes.join(", "));
      onSelect(selectedFunds);
    }, [fundOptions, onSelect]);

    return (
      <>
        <TitleField>{echo("choose-fund-investment")}</TitleField>
        <FormBox>
          <FormDropdown
            style={{
              borderBottomLeftRadius: isOpen ? 0 : "8px",
              borderBottomRightRadius: isOpen ? 0 : "8px",
            }}
          >
            <FormDropdownDiv1>
              <DropdownLabel>
                {selectedValue ? selectedValue : echo("choose-fund-investment")}
                {!selectedValue && <span>*</span>}
              </DropdownLabel>
              <DropdownIcon
                onClick={handleClick}
                loading="lazy"
                src={isOpen ? OpenIcon : CloseIcon}
              />
            </FormDropdownDiv1>
          </FormDropdown>
          {isOpen && (
            <ListDanaInvestasi>
              {fundOptions.map((option, index) => (
                <DanaInvestasi
                  style={{
                    backgroundColor: option?.checked ? "#E5EAEF" : "#FFF",
                  }}
                  key={index}
                >
                  <CheckboxDiv>
                    <Checkbox
                      onClick={() => {
                        if (option.tunai === "0") return;
                        setFundOptions((prevArray) => {
                          const newArray = [...prevArray];
                          const updatedObject = { ...newArray[index] };
                          updatedObject.checked = !updatedObject?.checked;
                          newArray[index] = updatedObject;
                          return newArray;
                        });
                      }}
                      style={{
                        borderRadius: "4px",
                        borderColor: "rgba(238, 238, 238, 1)",
                        borderStyle: "solid",
                        borderWidth: "2px",
                        backgroundColor: "#fff",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      {option.checked && <Img loading="lazy" src={Checked} />}
                    </Checkbox>
                  </CheckboxDiv>
                  <ContentDiv>
                    {option?.fund_code !== "" && (
                      <DanaInvestasiLabel>{option.fund}</DanaInvestasiLabel>
                    )}
                    <DanaInvestasiValueDiv
                      style={{ marginTop: option.fund_code === "" && 0 }}
                    >
                      <DanaInvestasiValueDiv1>
                        <Label>{echo("cash-value")}</Label>
                        <Value>
                          {option?.fund_code !== ""
                            ? formatPrice(
                                option?.tunai,
                                option?.currency === "IDR" ? "Rp" : "",
                                option?.currency === "IDR" ? "IDR" : ""
                              )
                            : formatPrice(
                                option?.tunai,
                                chosenPolicy?.currency === "IDR" ? "Rp" : "",
                                chosenPolicy?.currency === "IDR" ? "IDR" : ""
                              )}{" "}
                        </Value>
                      </DanaInvestasiValueDiv1>
                      {option?.fund_code !== "" && (
                        <DanaInvestasiValueDiv2>
                          <Label>{echo("total-unit")}</Label>
                          <Value>
                            {formatUnit(Number(option?.tunai) / option?.unit)}
                          </Value>
                        </DanaInvestasiValueDiv2>
                      )}
                    </DanaInvestasiValueDiv>
                  </ContentDiv>
                </DanaInvestasi>
              ))}
            </ListDanaInvestasi>
          )}
        </FormBox>
      </>
    );
  }
);

const TitleField = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--Primary-Color-Black, #000);
  text-overflow: ellipsis;
  width: 100%;
  font: 700 14px/24px Open Sans, sans-serif;
`;

const FormBox = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const FormDropdown = styled.div`
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 300;
  line-height: 156%;
  justify-content: center;
  padding: 21px 24px;
  border-radius: 8px;
`;

const FormDropdownExpand = styled.div`
  border-radius: 0px 0px 8px 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #68737a;
`;

const DropdownItem = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-White-primary, #fff);
  border-color: rgba(238, 238, 238, 1);
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  justify-content: center;
  padding: 12px 24px;
  font: 400 18px/156% Open Sans, sans-serif;

  &:hover {
    background-color: #e5eaef;
  }
`;

const DropdownItemOther = styled.div`
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-White-primary, #fff);
  display: flex;
  flex-direction: column;
  color: var(--Primary-Color-Black, #000);
  text-align: center;
  justify-content: center;
  padding: 12px 24px;
`;

const FormDropdownDiv1 = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 20px;
`;

const DropdownLabel = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--Primary-Color-Black, #000);
  text-overflow: ellipsis;
  font-family: Open Sans, sans-serif;

  span {
    color: var(--Primary-Color-Prudential-Red, #ed1b2e);
    text-align: center;
    font-family: Open Sans, sans-serif;
  }
`;

const DropdownIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 23px;
  margin: auto 0;
  cursor: pointer;
`;

const DropdownItemOtherDiv = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  flex-direction: column;
  padding: 21px 24px;
  text-align: left;
`;

const DropdownItemOtherBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DropdownItemOtherLabel = styled.div`
  font: 700 14px/24px Open Sans, sans-serif;
`;

const ListDanaInvestasi = styled.div`
  border-radius: 0px 0px 8px 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const DanaInvestasi = styled.div`
  background-color: #fff;
  border-color: rgba(238, 238, 238, 1);
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  display: flex;
  gap: 12px;
  padding: 12px 24px;
`;

const CheckboxDiv = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Checkbox = styled.div`
  border-radius: 4px;
  border-color: rgba(238, 238, 238, 1);
  border-style: solid;
  border-width: 2px;
  background-color: var(--Primary-White-primary, #fff);
  width: 24px;
  height: 24px;
`;

const ContentDiv = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #68737a;
  flex: 1;
`;

const DanaInvestasiLabel = styled.div`
  font: 600 18px/156% Open Sans, sans-serif;
`;

const DanaInvestasiValueDiv = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
`;

const DanaInvestasiValueDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.div`
  font: 700 14px/24px Open Sans, sans-serif;
`;

const Value = styled.div`
  margin-top: 8px;
  font: 600 12px/150% Open Sans, sans-serif;
`;

const DanaInvestasiValueDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 100%;
`;
