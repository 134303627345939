import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LanguagePolisku, useAxios, aesDecrypt } from "@pru-cnp/ui-polisku";
import { CircularProgress, Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

import Success from "../../assets/checkmark-circle-outline.svg";
import Failed from "../../assets/close-circle-outline.svg";

function Submit() {
  const navigate = useNavigate();
  const { echo } = LanguagePolisku();
  const [withdrawalForm] = useState(
    JSON.parse(aesDecrypt(window.localStorage.getItem("withdrawalForm"))) || {}
  );
  const [language] = useState(window.localStorage.getItem("language") || "id");
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    hitPPLSubmit();
  }, []);

  const handleNext = () => {
    if (isFailed) {
      hitPPLSubmit();
      return;
    }

    navigate("/");
    window.localStorage.removeItem("withdrawalForm");
  };

  const hitPPLSubmit = () => {
    let bankAccount = {};
    let billingInvoices = [];
    if (withdrawalForm?.bentukPembayaran?.value === 1) {
      billingInvoices = withdrawalForm?.premi.map((premi) => ({
        policy: {
          policyNo: premi?.noPolis,
        },
        billAmount: Number(premi.amount),
        billCycleFrom: "",
        billCycleTo: "",
      }));
    } else if (withdrawalForm?.bentukPembayaran?.value === 2) {
      bankAccount = withdrawalForm?.pakaiRekeningLain
        ? {
            bankName: withdrawalForm?.rekeningLain?.namaBank?.label,
            bankCode: withdrawalForm?.rekeningLain?.namaBank?.value,
            bankBranch: withdrawalForm?.rekeningLain?.cabangUnit,
            accountNo: withdrawalForm?.rekeningLain?.noRekening,
            accountName: withdrawalForm?.rekeningLain?.namaPemilik,
            accountType: withdrawalForm?.rekeningLain?.jenisMataUang?.label,
            attributes: {
              city: withdrawalForm?.rekeningLain?.kota,
              swiftCode: withdrawalForm?.rekeningLain?.swiftCode,
              country: withdrawalForm?.rekeningLain?.negara?.label,
              countryId: withdrawalForm?.rekeningLain?.negara?.value,
              taxAccount: withdrawalForm?.rekeningLain?.npwp,
            },
          }
        : {
            bankName: withdrawalForm?.rekeningUtama?.bankCode?.label,
            bankCode: withdrawalForm?.rekeningUtama?.bankCode?.value,
            accountName: withdrawalForm?.rekeningUtama?.namaPemilik,
            bankBranch: "",
            accountNo: withdrawalForm?.rekeningUtama?.noRekening,
          };
    } else {
      billingInvoices = withdrawalForm?.premi?.map((premi) => ({
        policy: {
          policyNo: premi?.noPolis,
        },
        billAmount: Number(premi.amount),
        billCycleFrom: "",
        billCycleTo: "",
      }));
      bankAccount = withdrawalForm?.pakaiRekeningLain
        ? {
            bankName: withdrawalForm?.rekeningLain?.namaBank?.label,
            bankCode: withdrawalForm?.rekeningLain?.namaBank?.value,
            bankBranch: withdrawalForm?.rekeningLain?.cabangUnit,
            accountNo: withdrawalForm?.rekeningLain?.noRekening,
            accountName: withdrawalForm?.rekeningLain?.namaPemilik,
            accountType: withdrawalForm?.rekeningLain?.jenisMataUang?.label,
            attributes: {
              city: withdrawalForm?.rekeningLain?.kota,
              swiftCode: withdrawalForm?.rekeningLain?.swiftCode,
              country: withdrawalForm?.rekeningLain?.negara?.label,
              countryId: withdrawalForm?.rekeningLain?.negara?.value,
              taxAccount: withdrawalForm?.rekeningLain?.npwp,
            },
          }
        : {
            bankName: withdrawalForm?.rekeningUtama?.bankCode?.label,
            bankCode: withdrawalForm?.rekeningUtama?.bankCode?.value,
            accountName: withdrawalForm?.rekeningUtama?.namaPemilik,
            bankBranch: "",
            accountNo: withdrawalForm?.rekeningUtama?.noRekening,
          };
    }

    let documents = [];

    documents = withdrawalForm?.dokumen?.map((doc) => ({
      id: doc.jenisDokumen?.value,
      category: doc.jenisDokumen?.label,
      contentType: doc?.file?.type,
      imageFormat: doc?.file?.type?.split("/").pop(),
      content: doc?.file?.base64,
    }));
    if (withdrawalForm?.cvp) {
      if (documents) {
        documents.push(withdrawalForm?.cvp);
      } else {
        documents = [withdrawalForm?.cvp];
      }
    }
    const body = {
      originalPolicyNo: withdrawalForm?.chosenPolicy?.policy_no,
      withdrawalType:
        withdrawalForm?.tipePenarikanDana?.value === 0 ? "AMOUNT" : "UNIT",
      purpose: withdrawalForm?.alasanPenarikanDana?.value,
      withdrawalFunds: [
        ...withdrawalForm?.danaInvestasi.map((fund) => ({
          fund_code: fund.fund_code,
          fund_name: fund.fund_name,
          currency: fund.currency,
          value: Number(fund.withdrawAmount),
        })),
      ],
      payment: {
        bankAccount,
        billingInvoices,
      },
      documents,
      validationSignature: withdrawalForm?.validationSignature,
    };
    setTimeout(() => {
      useAxios({
        method: "post",
        url: "/withdrawal/ppl-submit",
        body,
      }).then((response) => {
        const { status, data } = response;
        if (response) {
          if (status == 200) {
            if (data?.resp_code == 0) {
              setLoading(false);
              const responseSuccess =
                language === "id"
                  ? data?.resp_desc?.Indonesian
                  : data?.resp_desc?.English;
              setSuccessMessage(responseSuccess);
            } else {
              setIsFailed(true);
              const responseError =
                language === "id"
                  ? data?.resp_desc?.Indonesian
                  : data?.resp_desc?.English;
              alert(responseError || echo("something-went-wrong"));
            }
          } else {
            setIsFailed(true);
            const responseError =
              language === "id"
                ? data?.resp_desc?.Indonesian
                : data?.resp_desc?.English;
            alert(responseError || echo("something-went-wrong"));
          }
          setLoading(false);
        }
        setLoading(false);
      });
    }, 5000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        paddingTop: "20vh",
        paddingBottom: "30vh",
      }}
    >
      {!loading && <img src={isFailed ? Failed : Success} alt="icon" />}
      {loading ? (
        <>
          <CircularProgress
            style={{
              marginBottom: "20px",
            }}
          />
          <Typography>Mengirim Formulir...</Typography>
        </>
      ) : (
        <Typography
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontWeight: "bold",
            color: isFailed ? "red" : "black",
          }}
        >
          {isFailed
            ? errorMessage || "Formulir Penarikan Dana Gagal Terkirim"
            : successMessage || "Formulir Penarikan Dana Berhasil Terkirim"}
        </Typography>
      )}
      <BtnLanjut
        style={{
          display: loading ? "none" : "block",
        }}
        onClick={handleNext}
      >
        {isFailed ? "Kirim Ulang" : echo("home")}
      </BtnLanjut>
    </Box>
  );
}

export default Submit;

const BtnLanjut = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  color: var(--Primary-Color-White, #fff);
  white-space: nowrap;
  text-align: center;
  font: 600 18px/156% Open Sans, sans-serif;
  border: 0;
  cursor: pointer;
  padding: 12px 60px;
  margin-top: 40px;
`;
